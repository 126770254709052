import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { InputAdornment, Grid, IconButton, Typography, Button, Stack, TextField, Card, Avatar } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { TextareaAutosize } from '@mui/material';
import { makeStyles } from '@mui/material';

function formatDate(date:string) {
    if (!date) {
        return "";
    }
    const d = new Date(date);
    // 無効な日付をチェック
    if (isNaN(d.getTime())) {
        return "";
    }
    // UTC時間から日本時間に変換 (UTC+9時間)
    const JSTOffset = 9 * 60 * 60 * 1000;
    const jstDate = new Date(d.getTime() + JSTOffset);
    const year = jstDate.getUTCFullYear();
    const month = `${jstDate.getUTCMonth() + 1}`.padStart(2, '0');
    const day = `${jstDate.getUTCDate()}`.padStart(2, '0');
    
    return `${year}-${month}-${day}`;
}

interface TagsProps {
    value: string | null,
    placeholder: string,
    setValue: Dispatch<SetStateAction<string>>
    finishFunc?: () => void;
    max?: number,
    maxdisplay?: boolean,
    error?: boolean,
    border?: string,
    padding?: string,
    backgroundColor?: string;
    type?: string;
    fontSize?: string;
    disabled?: boolean;
    width?: string;
    rows?:number;
    errorCheck?: (text:string)=>boolean
}

const LimitedMultilineOutlinedBordered: React.FC<TagsProps> = ({ value, setValue, max, maxdisplay, placeholder, error, border, padding, backgroundColor, fontSize, disabled, errorCheck, rows, width, finishFunc, type }) => {
    const [valid, setValid] = useState<boolean>(true)
    useEffect(() => {
        if(value !== null && type === undefined && value?.trim() !== "" && errorCheck && errorCheck(value)){
            setValid(false)
        }else{
            setValid(true)
        }
    }, [value]);
    
    return(
        <Stack direction={"column"} sx={{width:"100%"}} >
            <TextField type={type} multiline={rows ? rows > 1 ? true : false : false} rows={rows ? rows: 1} variant="outlined" disabled={disabled ? disabled : false} sx={{ width:"100%",
                '& .MuiOutlinedInput-root': {
                    paddingRight: "0px",
                    '& fieldset': {
                        border: "none",
                        borderRadius: '10px',
                    },
                },

                "& .MuiInputBase-multiline ":{
                    padding: "0px 0px", // マルチラインの場合、余白をリセットする
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {border: "none", },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': { border: "none"},
                }}
                inputProps={{style: {fontSize: fontSize ? fontSize : 16, border: !valid || error ? "1.5px solid red" : border ? border : "none", borderRadius:"10px", padding: padding ? padding :14, lineHeight: '20px', backgroundColor:backgroundColor ? backgroundColor : "#F6F6F6"},}}
                value={type==="date" ? formatDate(value ?? "") : value}
                placeholder={placeholder}
                onChange={(e) => {
                    if(max && e.target.value.length > max){
                        setValue(e.target.value.substring(0, max))
                    }else{
                        if(type==="date"){
                            setValue(e.target.value + "T00:00:00.000Z");
                        }else{
                            setValue(e.target.value)
                        }
                    }

                    if(errorCheck && errorCheck(e.target.value)){
                        setValid(false)
                    }else{
                        setValid(true)
                    }

                    if(e.target.value === ""){
                        setValid(true)
                    }
                }}
                onKeyDown={(event) => {
                        if (event.key === 'Enter' && event.metaKey) {
                          event.preventDefault(); // デフォルトのEnterキーの動作を防止する場合
                          // ここに特定の処理を追加
                          if(finishFunc) finishFunc();
                        }
                }}
            />
            {max && maxdisplay && 
                <Stack direction={"row"} justifyContent={"flex-end"}>
                    <Typography sx={{fontSize: 14, color: value?.length ? value?.length >= max ? "#e60000" : "grey" : "grey"}}>
                        {value?.length} / {max}
                    </Typography>
                </Stack>
            }
        </Stack>
    )
    
}

export default LimitedMultilineOutlinedBordered;
