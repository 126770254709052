import React, { useState, useEffect, useContext } from 'react';
import { Grid,Stack, FormGroup, Checkbox, Card, Button, TextField, Typography,InputAdornment, Box } from '@mui/material';
import { useIsMobile } from "../components/isMobile"
import LimitedNormalOutlinedBordered from './../components/Textfield/limited_normal_outlined_bordered';
import { useMutation } from 'react-query';
import axios from 'axios';
import LimitedMultilineOutlinedBordered from './../components/Textfield/limited_multiline_outlined_bordered';

interface Props {
    success_func: () => void;
}


const Login: React.FC<Props> = (props) =>  {
  const isMobile = useIsMobile()
  const [user_name, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  // ログインユーザーのミューテーションを定義
const loginUser = useMutation(
    (postData: { user_name: string, password: string }) => {
      return axios.post(process.env.REACT_APP_API_URL + '/login/admin', postData, {
        withCredentials: true, // クッキーを含めるオプション
      });
    },
    {
      onSuccess: (res) => {
        props.success_func();
      },
      onError: (error) => {
        console.error('Login failed:', error);
        alert('ログインに失敗しました。再度お試しください。');
      },
    }
  );

  
  return (
    <Stack  height={"100%"} minHeight={"100vh"} justifyContent={"center"} alignItems={"center"} sx={{backgroundColor:"#F6F8FA"}}>
        <Stack width={isMobile ? "80%" : "30%"} minWidth={"400px"} p={1.5} minHeight={"400px"} alignItems={"center"} sx={{backgroundColor: "white", borderRadius:"20px" }}>
            <img alt="" src={process.env.REACT_APP_PUBLIC_URL+"/RE-IDEA.png"} style={{width:"80%", padding: "0px 10px", margin:"20px 0px"}}/>

            <Stack spacing={2} mt={4} sx={{width:"80%"}}>
                <LimitedNormalOutlinedBordered value={user_name} setValue={setUserName} placeholder='管理者ユーザーネームを入力' />
                <LimitedMultilineOutlinedBordered type={"password"} value={password} setValue={setPassword} placeholder='パスワードを入力' />
            </Stack>

            <Stack mt={4} pb={0.5} justifyContent={"center"} alignItems={"center"} sx={{ cursor:"pointer", zIndex:10, height:"35px", width:"125px", backgroundColor: "#383E86", borderRadius:"5px", ":hover":{opacity: 0.9}}}
                    onClick={() => {loginUser.mutate({user_name:user_name, password:password})}}>
                <Box textAlign={"center"} alignItems={"center"} component="div" width={"100%"} sx={{color: "white", fontSize: 14, fontWeight: 'medium',letterSpacing: '0px'}}>
                    ログイン
                </Box>
            </Stack>
        </Stack>
    </Stack>
  )

}

export default Login;
