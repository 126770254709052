import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { FormControl,SelectChangeEvent, InputLabel, Select, MenuItem,MenuProps, Grid, styled, Paper, IconButton, Typography, Button, Stack, TextField, Card, Avatar } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { TextareaAutosize } from '@mui/material';
import { makeStyles } from '@mui/material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';



interface TagsProps {
    title?: string;
    labels: string[];
    default_lables?: string[]
    sort_value: string;
    ids?: string[];
    setSortValue: Dispatch<SetStateAction<string>>;
    bold?:boolean;

    isMobile?: boolean;
    ratio? :number;
}

const FlexSortSelector: React.FC<TagsProps> = ({ title, labels, default_lables, sort_value, setSortValue, ratio, ids, isMobile, bold }) => {
    // カスタムスタイルのPaperコンポーネント
    const menuProps = {
        PaperProps: {
            sx: {
                borderRadius: '15px',
                minWidth: `${140 * (ratio ? ratio : 1)}px`,
                marginTop: '10px',
            },
        },
    };
    return(
        <Stack spacing={1}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 100 * (ratio ? ratio : 1)}}>
                {title && <InputLabel sx={{ fontSize:isMobile ? '14px' : '16px' }}>{title}</InputLabel>}
                <Select value={ids ? labels[Number(sort_value)-1] === "null" ? "" : labels[Number(sort_value)-1] : sort_value} onChange={(e) => {
                    if(ids){
                        setSortValue(ids[labels.indexOf(e.target.value)])
                    }else{
                        setSortValue(e.target.value)
                    }
                }} sx={{fontSize: 14 * (ratio ? ratio : 1) +"px", fontWeight: bold===false ? "medium" : "bold", textDecoration: 'none' }}
                    MenuProps={menuProps}
                >
                    {[...(default_lables ?? []), ...labels].map((label:string, index) => {
                        return (
                            <MenuItem value={label} sx={{fontSize:14 * (ratio ? ratio : 1) +"px", padding: 5 * (ratio ? ratio : 1) + "px" + 25 * (ratio ? ratio*2 : 2) + "px", minHeight: 32 * (ratio ? ratio : 1) + "px"}}>{label}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Stack>
    )
    
}

export default FlexSortSelector;
