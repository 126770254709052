import { medium, simple } from "@/interfaces/recruitment";
import { medium as medium_idea } from "@/interfaces/idea";
import axios from"axios"
import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import { Stack, Paper, Snackbar, Typography,Table, CircularProgress, TableSortLabel, TableContainer, TableHead, TableBody, TableRow, TableCell, Button, Box, Divider, Checkbox } from "@mui/material";
import { useQuery, useMutation, UseQueryResult, useQueryClient } from 'react-query';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz'
import PopupConfirmation from "../components/popup/confirmation";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LimitedNormalOutlinedBordered from "../components/Textfield/limited_normal_outlined_bordered";
import LimitedMultilineOutlinedBordered from "../components/Textfield/limited_multiline_outlined_bordered";
import { MyContext } from "./../routes/router";
import FlexSortSelector from "./../components/select/flexSort";
import PopupApplyEditAndPreview from "./../components/popup/ApplyEditAndPreview";
import FixedPlus from "./../components/buttons/fixedPlus";

const generateHeader = (before: number, heads:string[], after: number) => {
  return (
    <TableHead sx={{ zIndex:2, backgroundColor:"white", position:"sticky", top:0}}>
      <TableRow sx={{width:"100%", backgroundColor:"white"}}>
        {Array(before).fill(0).map((value:number, index:number) => {
          return (
            <TableCell align="left" sx={{paddingBottom:"20px"}}>
            </TableCell>
          )
        })}
        {heads.map((label: string, index: number,) => {
          return (
            <TableCell align="left" sx={{paddingBottom:"20px"}}>
              <Typography component="div" onClick={() => {}}sx={{ color:"grey", display: '-webkit-box', WebkitBoxOrient: 'vertical', wordWrap: 'break-word', WebkitLineClamp: 1, overflow: "hidden",}}>
                <Box sx={{  }}>
                  {label}
                </Box>
              </Typography> 
            </TableCell>
          )
        })}
        {Array(after).fill(0).map((value:number, index:number) => {
          return (
            <TableCell align="left" sx={{paddingBottom:"20px"}}>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

// ルーターによりURLで分岐
function Applies () {
  const queryClient = useQueryClient();
  const { categories } = React.useContext(MyContext);

  // react-query 便利で良い。しかし凝縮性は悪いように思われるが...。使い回す場合はどうするのだ？
  const { data:recruitments }: UseQueryResult<medium[] | null, Error> = useQuery("recruits_medium", () => fetch(process.env.REACT_APP_API_URL+'/recruitments/find/medium').then(res =>res.json()));
  const { data:user_creaters }: UseQueryResult<any[] | null, Error> = useQuery("user_creaters", () => fetch(process.env.REACT_APP_API_URL+'/users/findcreaters').then(res =>res.json()));
  const [ selectedRecruitment, setSelectedRecruitment ] = useState<medium | null>(null)
  const [ showPopupConfirmation, setShowPopupConfirmation ] = useState<boolean>(false)
  const [ showPopupApplyEditAndPreview, setShowPopupApplyEditAndPreview ] = useState<boolean>(false)
  const [ showSnackBar, setShowSnackBar ] = useState<boolean>(false)
  const [ userRecruitLikes, setUserRecruitLikes ] = useState<any>([])
  const [ ideas_recruit, setIdeasRecruit ] = useState<medium_idea[]>([])
  const [ editColumn, setEditColumn ] = useState<string>("")
  const [ editValue, setEditValue ] = useState<string>("")
  const selectedRecruitmentID = selectedRecruitment?.recruit_id;

  const [ deletedTitle, setDeletedTitle ] = useState<string>("")

  
  const removeRecruit = useMutation((postData : {recruit_id: string}) => {
    return axios.post(process.env.REACT_APP_API_URL+'/recruitments/remove/normal', postData)
  }, { onSuccess: () => { queryClient.invalidateQueries('recruits_medium'); } })

  const updateRecruit = useMutation((postData : {recruit_id: string, column: string, newValue: string}) => {
    return axios.post(process.env.REACT_APP_API_URL+'/recruitments/update/onecolumn', postData)
  }, { onSuccess: (res) => { setEditColumn(""); queryClient.invalidateQueries('recruits_medium'); } })

  const findUserRecruitLikes = useMutation((postData : {recruit_id: string}) => {
    return axios.post(process.env.REACT_APP_API_URL+'/user_recruit_likes/find/findbyrecruit', postData)
  },{ onSuccess: (res) => { setUserRecruitLikes(res.data);},})

  const findPostedIdeas = useMutation((postData : {recruit_id: string}) => {
    return axios.post(process.env.REACT_APP_API_URL+'/ideas/find/mediumbyrecruit', postData)
  },{ onSuccess: (res) => { setIdeasRecruit(res.data);},})

  useEffect(()=>{
    if(selectedRecruitment?.recruit_id !== undefined){
      findUserRecruitLikes.mutate({recruit_id: selectedRecruitment.recruit_id})
    } 
  }, [selectedRecruitment])

  useEffect(()=>{
    setSelectedRecruitment(recruitments?.filter((recruit:medium) => recruit.recruit_id === selectedRecruitmentID)[0] ?? null)
  }, [recruitments])

  useEffect(()=>{
    if(selectedRecruitment?.recruit_id) setDeletedTitle(selectedRecruitment.title)
  }, [selectedRecruitmentID])

  const stackRef = useRef<HTMLDivElement>(null);
  const stackRef2 = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  
  const HEAD : {label: string, value:string}[] = [
    {label: "画像", value:"image_name"},
    {label: "題名", value:"title"},
    {label: "投稿者", value: "user_name"},
    {label: "投稿数", value: "num_of_ideas"},
    {label: "募集開始日", value: "createdAt"},
    {label: "募集締切", value: "deadline"},
  ]

  return (
    <Stack padding={5} sx={{backgroundColor: "#F6F8FA"}}>
      {/* 上部グラフ類の描画 */}
      {/* <Stack width={"100%"} height={"300px"} spacing={2} direction={"row"} justifyContent={"space-between"}>
        <Stack width={"60%"} height={"100%"} alignItems={"center"} justifyContent={"center"} padding={"10px"} sx={{backgroundColor:"white", borderRadius:"20px"}}>
          <Typography sx={{ fontWeight:"bold", color:"lightgrey", fontSize:16, padding:"50px 10px 20px 10px"}}>
            IDEA募集に関するグラフ
          </Typography>
        </Stack>
        <Stack width={"35%"} height={"100%"} alignItems={"center"} justifyContent={"center"} padding={"10px"} sx={{backgroundColor:"white", borderRadius:"20px"}}>
          <Typography sx={{ fontWeight:"bold", color:"lightgrey", fontSize:16, padding:"50px 10px 20px 10px"}}>
            IDEA募集に関する円グラフ
          </Typography>
        </Stack>
      </Stack> */}

      {/* 下部1 アイデア一覧 削除 */}
      <Stack mt={1} width={"100%"} alignItems={"center"} justifyContent={"center"}  spacing={2} sx={{ position:"relative", backgroundColor:"white", borderRadius:"20px"}}>
        <Stack direction={"row"} justifyContent={"space-between"} width={"95%"}>
          <Typography sx={{ fontWeight:"bold", color:"#616161", fontSize:20, padding:"40px 10px 10px 10px"}}>
            Applies
          </Typography>
        </Stack>
        <Divider sx={{width:"95%"}}/>
        <TableContainer sx={{ maxHeight: 600, alignItems:"center", position:"relative" }} component={Stack}>
          <Table size='small' sx={{ width:"95%"}}>
            {generateHeader(1,HEAD.map((head:{ label: string, value: string }, index: number,) => head.label),1)}
            <TableBody sx={{ }}>
              {recruitments?.map((recruit:medium) => {
                return(
                  <TableRow sx={{}}>
                    <TableCell align="left" sx={{maxWidth:"120px"}}>
                      <Checkbox/>
                    </TableCell>
                    {HEAD.map((head:{ label: string, value: string }, index: number,) => {
                      return (
                        <TableCell align="left" sx={{maxWidth:head.value === "title" ? "200px" : "100px"}}>
                          <Typography component="div" onClick={() => {
                            if(head.value === "title" || head.value === "image_name"){
                              setSelectedRecruitment(recruit) 
                              findPostedIdeas.mutate({recruit_id: recruit?.recruit_id ?? ""})
                              stackRef.current?.scrollIntoView({ behavior: 'smooth' });
                            }}} sx={{ cursor:head.value === "title" || head.value === "image_name" ? "pointer" : "arrow", display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, overflow: "hidden",}}>
                            <Box sx={{ color:head.value === "title" ? "royalblue" : "inherit", fontSize:"13px" }}>
                              {head.value === "image_name" && <img src={process.env.REACT_APP_PUBLIC_URL+"/images/"+recruit.image_name} style={{ objectFit: 'cover', width:"40px", borderRadius:"15px"}}/>}
                              {head.value === "title" && recruit.title}
                              {head.value === "user_name" && recruit.user_name}
                              {head.value === "num_of_ideas" && recruit.num_of_ideas}
                              {head.value === "createdAt" && format(toZonedTime(recruit.createdAt, "UTC"),'M/d H:mm')}
                              {head.value === "deadline" && format(toZonedTime(recruit.deadline, "UTC"),'M/d H:mm')}
                            </Box>
                          </Typography> 
                        </TableCell>
                      )
                    })}
                    <TableCell align="left" sx={{maxWidth:"120px"}}>
                      <Button onClick={async () => {
                        setSelectedRecruitment(recruit);
                        setShowPopupConfirmation(true) }}>削除</Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      {/* 下部2 アイデア詳細 編集 */}
      <Stack ref={stackRef} mt={7} width={"100%"} height={"600px"} alignItems={"center"} justifyContent={"flex-start"}  spacing={2} sx={{backgroundColor:"white", borderRadius:"20px"}}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"} width={"95%"}>
          <Typography sx={{ fontWeight:"bold", color:"#616161", fontSize:20, padding:"40px 10px 10px 10px"}}>
            Apply Details
          </Typography>
          <Typography sx={{ color:"#616161", fontSize:16, padding:"10px"}}>
            {selectedRecruitment?.title}
          </Typography>
        </Stack>
        <Divider sx={{width:"95%"}}/>

        {selectedRecruitment?.recruit_id !== undefined &&
          <TableContainer sx={{ width:"95%", maxHeight: 750, alignItems:"flex-start", position:"relative" }} component={Stack}>
            <Table size='small' sx={{ width:"100%"}}>
              {generateHeader(0,["column", "value",],1)}
              <TableBody sx={{ }}>
                {Object.keys(selectedRecruitment ?? {})?.map((key:string, index:number) => {
                  return(
                    <TableRow sx={{}}>
                      <TableCell align="left" sx={{maxWidth: "80px"}}>
                        <Typography component="div" sx={{ }}>
                          <Box sx={{ color: "inherit", fontSize:"13px" }}>
                            {key}
                          </Box>
                        </Typography> 
                      </TableCell>
                      <TableCell align="left" sx={{maxWidth: "170px"}}>
                        <Typography component="div" sx={{wordWrap: 'break-word', whiteSpace:"pre-wrap"  }}>
                          <Box sx={{ color: "inherit", fontSize:"13px" }}>
                            {editColumn !== key ? 
                              // 編集状態でない時
                              ["deadline", "createdAt", "updatedAt"].includes(key) ? Object.values((selectedRecruitment ?? {}))[index] !== null ? format(toZonedTime(Object.values((selectedRecruitment ?? {}))[index], "UTC"),'yyyy年M月d日 H時mm分') : null :
                              // ["user_id", "profile_image_name", "profile_image_uri"].includes(key) ? Object.values((selectedRecruitment ?? {}))[index] :
                              Object.values((selectedRecruitment ?? {}))[index]:

                              // 編集状態の時
                              editColumn.includes("category_name") ? 
                                <FlexSortSelector ids={["null", ...categories.map((category:{category_id:number, name:string}) => String(category.category_id+1))]} labels={["null", ...categories.map((category:{category_id:number, name:string}) => category.name)]} sort_value={editValue} setSortValue={setEditValue} bold={false} />
                              :
                              editColumn.includes("user_name") ? 
                                <FlexSortSelector labels={["null", ...user_creaters?.map((user: any) => user.user_name) ?? []]} sort_value={editValue} setSortValue={setEditValue} bold={false} />
                              :
                              <LimitedMultilineOutlinedBordered rows={key === "description" ? 3 : 1} type={key.includes("num") ? "number" : key.includes("deadline") ? "date" : undefined} padding="7px 7px" fontSize="12px" value={editValue} placeholder="変更後の値を入力" setValue={setEditValue} finishFunc={() =>{if(buttonRef) buttonRef.current?.click();}} />
                            }
                          </Box>
                        </Typography> 
                      </TableCell>
                      <TableCell align="left" sx={{maxWidth:"50px"}}>
                        {["title", "image_name", "benefit_description", "benefit_num", "deadline", "description", "tag1", "tag2", "tag3", "tag4", "tag5", "category_name1", "category_name2", "category_name3", "user_name" ].includes(key) &&
                        <>
                          <Button onClick={() => {
                            if(editColumn === key){
                              setEditColumn("")
                              setEditValue("")
                            }else{
                              setEditColumn(key); 
                              setEditValue( key.includes("category_name") ? String(categories.map((category:{category_id:number, name:string}) => category.name).indexOf(Object.values((selectedRecruitment ?? {}))[index])+2): Object.values((selectedRecruitment ?? {}))[index]);
                              }
                          }}>{editColumn === key ? "やめる" : "編集"}</Button>
                          {editColumn === key &&
                            <Button ref={buttonRef} onClick={() => {
                              let _editColumn = editColumn.includes("category_name") ? editColumn.replace("category_name", "category_id") : 
                              editColumn.includes("user_name") ? "user_id" : editColumn
                              let _editValue = editValue==="null" ? "null" : 
                                               editColumn === "benefit_num" ? editValue === "" ? "0" :  editValue :
                                               editColumn.includes("category_name") ? String(Number(editValue) -1) :
                                               editColumn.includes("user_name") ? user_creaters?.filter((user: any) => user.user_name === editValue)[0]?.user_id ?? "null"
                                               : editValue
                              updateRecruit.mutate({recruit_id:selectedRecruitment?.recruit_id ?? "", column:_editColumn, newValue:_editValue})}}>決定</Button>
                          }
                        </>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        }

      </Stack>

      {/* 下部3 アイデアへのいいねと出資 */}
      <Stack ref={stackRef2} width={"100%"}  mt={7}  height={"500px"} spacing={2} direction={"row"} justifyContent={"space-between"}>
        {/* いいね */}
        <Stack width={"40%"} height={"500px"} alignItems={"center"} justifyContent={"flex-start"}  spacing={2} sx={{backgroundColor:"white", borderRadius:"20px"}}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"} width={"95%"}>
            <Typography sx={{ fontWeight:"bold", color:"#616161", fontSize:18, padding:"20px 10px 0px 20px"}}>
              Likes
            </Typography>
          </Stack>
          <Divider sx={{width:"95%"}}/>

          {(userRecruitLikes?.length ?? 0) > 0 && 
            <TableContainer sx={{ width:"95%", maxHeight: 600, alignItems:"flex-start", position:"relative" }} component={Stack}>
              <Table size='small' sx={{ width:"100%"}}>
                {generateHeader(1,["user_name", "createdAt",],0)}
                <TableBody sx={{ }}>
                  {userRecruitLikes?.map((like:any, index:number) => {
                    if(like.deletedAt !== null){return null;}
                    return(
                      <TableRow sx={{}}>
                        <TableCell align="left" sx={{maxWidth: "80px"}}>
                          <FavoriteIcon sx={{color:"#ff5252", fontSize:"20px"}}/>
                        </TableCell>
                        <TableCell align="left" sx={{maxWidth: "80px"}}>
                          <Typography component="div" sx={{ }}>
                            <Box sx={{ color: "inherit", fontSize:"13px" }}>
                              {like.user_name}
                            </Box>
                          </Typography> 
                        </TableCell>
                        <TableCell align="left" sx={{maxWidth: "170px"}}>
                          <Typography component="div" sx={{wordWrap: 'break-word',  }}>
                            <Box sx={{ color: "inherit", fontSize:"13px" }}>
                              {format(toZonedTime(like.createdAt, "UTC"),'M/d H:mm')}
                            </Box>
                          </Typography> 
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Stack>

        {/* 投稿されたアイデア一覧 */}
        <Stack width={"60%"} height={"500px"} alignItems={"center"} justifyContent={"flex-start"}  spacing={2} sx={{backgroundColor:"white", borderRadius:"20px"}}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"} width={"95%"}>
            <Typography sx={{ fontWeight:"bold", color:"#616161", fontSize:18, padding:"20px 10px 0px 20px"}}>
              Posted Ideas
            </Typography>
          </Stack>
          <Divider sx={{width:"95%"}}/>

          {(ideas_recruit?.length ?? 0) > 0 && 
            <TableContainer sx={{ width:"95%", maxHeight: 600, alignItems:"flex-start", position:"relative" }} component={Stack}>
              <Table size='small' sx={{ width:"100%"}}>
                {generateHeader(1,["title", "user_name","createdAt"],0)}
                <TableBody sx={{ }}>
                  {ideas_recruit?.map((idea:medium_idea, index:number) => {
                    return(
                      <TableRow sx={{}}>
                        <TableCell align="left" sx={{maxWidth: "40px"}}>
                          <img src={process.env.REACT_APP_PUBLIC_URL+"/images/"+idea.image_name} style={{ objectFit: 'cover', width:"40px", borderRadius:"15px"}}/>
                        </TableCell>
                        <TableCell align="left" sx={{maxWidth: "160px"}}>
                          <Typography component="div" sx={{ }}>
                            <Box sx={{ color: "inherit", fontSize:"13px" }}>
                              {idea.title}
                            </Box>
                          </Typography> 
                        </TableCell>
                        <TableCell align="left" sx={{maxWidth: "60px"}}>
                          <Typography component="div" sx={{ }}>
                            <Box sx={{ color: "inherit", fontSize:"13px" }}>
                              {idea.user_name}
                            </Box>
                          </Typography> 
                        </TableCell>
                        <TableCell align="left" sx={{maxWidth: "50px"}}>
                          <Typography component="div" sx={{wordWrap: 'break-word',  }}>
                            <Box sx={{ color: "inherit", fontSize:"13px" }}>
                              {format(toZonedTime(idea.createdAt, "UTC"),'M/d H:mm')}
                            </Box>
                          </Typography> 
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Stack>
      </Stack>

      <PopupConfirmation type="apply" title={selectedRecruitment?.title ?? ""} open={showPopupConfirmation} handleClose={()=>{setShowPopupConfirmation(false)}} deleteFunc={async () => {
          await removeRecruit.mutate({recruit_id:selectedRecruitment?.recruit_id ?? ""})
          setShowSnackBar(true);
      }}/>
      <PopupApplyEditAndPreview open={showPopupApplyEditAndPreview} handleClose={()=>{setShowPopupApplyEditAndPreview(false)}} />
      <FixedPlus onClick={()=>{setShowPopupApplyEditAndPreview(true)}} />

      <Snackbar
        open={showSnackBar}
        autoHideDuration={6000}
        onClose={()=>{setShowSnackBar(false)}}
        message={"『"+(deletedTitle) +"』 が正常に削除されました"}
      />

    </Stack>
  );
}
  
  export default Applies;
  