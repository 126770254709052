import React, { useRef, useState, ChangeEvent } from "react";
import { styled } from "@mui/material/styles";
import { Container, Button, Typography, Box, IconButton, Stack } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import { Dispatch, SetStateAction } from 'react';

import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import imageCompression from 'browser-image-compression';


export const compressImage = async (image:File, success_func:(compressedFile: File)=>void) => {
    try {
      const compressedFile = await imageCompression(image, {
        maxSizeMB: 2.0, // 500KB以下に圧縮する
        maxWidthOrHeight: 2560, // 画像の最大幅または最大高さを指定
      });
      success_func(compressedFile)
    } catch (error) {
      console.error('画像の圧縮中にエラーが発生しました:', error);
    }
  };
  

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box mb={2} mt={1} sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1,  }}>
        <LinearProgress variant="determinate" {...props} sx={{height:"9px", borderRadius:"15px"}} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography sx={{fontSize: 20}} color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const Input = styled("input")({
  display: "none"
});



// Propsの型を定義
interface Props {
  image: string;
  setImage: Dispatch<SetStateAction<string>>;
  getUploadedFile: (file: string) => void;
  display_image: string;
  resetImage: ()=>void;
  loading: boolean;
  progress:number;
  error?:boolean;
  ratio?: number;
}


const Upload :React.FC<Props> = ({ getUploadedFile, image, setImage, display_image, resetImage, loading, progress, error, ratio}) => {
  
  const onChange = async (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    const file = files[0];

    compressImage(file, (file:File)=>{
      // 圧縮された画像をBase64形式に変換
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const compressedImageBase64 = reader.result;
        // ここで必要な処理を行う
        setImage(compressedImageBase64 as string)
        getUploadedFile(compressedImageBase64 as string);
      };
    })
  };

  const handleDrop = (e:any) => {
    e.preventDefault();
    onChange(e)
  };

  const handleDragOver = (e:any) => {
    e.preventDefault();
  };


  return (
    <label htmlFor="contained-button-file">

      <Box
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        sx={{
          border: error ? '1.5px solid red' : '2px dashed #ccc',
          borderRadius: '5px',
          padding: display_image == "" ? (ratio ? ratio * 40 : 40) +'px '+(ratio ? ratio * 20 :20)+'px' : (ratio ? ratio * 10 : 10) +'px '+(ratio ? ratio * 20 :20)+'px' ,
          textAlign: 'center',
          margin: '0px auto',
          cursor: display_image == "" ? !loading ? 'pointer' : 'arrow' : "arrow",
          backgroundColor: "#F6F6F6"
        }}
      >
      {loading && <LinearProgressWithLabel value={progress} />}
      { display_image == "" ? 
          loading == true ? 
          // AIで画像を生成しているとき
          <>
            <Typography sx={{marginBottom:"30px", fontSize: ratio ? ratio * 16 : 16, fontWeight:"bold", color:"#ACACAC"}}>画像を準備中</Typography>
            <CircularProgress sx={{color:"#ACACAC"}}/>
          </> :

          // 通常の待ち状態
          <>
            <Input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={(e) => {onChange(e)}}
              onClick={(e:any) => {
                // valueを初期化する。こうしないと再度発火しない。
                e.target.value = '';
              }}
            />
            <ImageIcon sx={{color:"#ACACAC", fontSize:"60px"}}/>
            <Typography sx={{fontSize:ratio ? ratio * 12 : 12, fontWeight:"bold", color:"#ACACAC"}}>ドラッグアンドドロップ</Typography>
            <Typography sx={{fontSize:ratio ? ratio * 12 : 12, fontWeight:"bold", color:"#ACACAC"}}>またはクリックで画像をアップロード</Typography>
            <Typography sx={{margin:"10px auto", fontSize:ratio ? ratio * 12 : 12, fontWeight:"bold", color:"#ACACAC"}}>OR</Typography>
            <Typography sx={{fontSize:ratio ? ratio * 12 : 12, fontWeight:"bold", color:"#ACACAC"}}>AIで画像を生成する</Typography>
          </>
       :
          //  画像が存在している状態
          <div style={{ position: 'relative', display: 'inline-block', width:"100%", height:"100%"}}>
            
            <div style={{ position: 'relative', display: 'inline-block', width:"50%"}}>
              
              <img src={display_image} style={{width: "100%"}}/>
              
              {loading == false && 
                <IconButton
                  onClick={(e) => {e.stopPropagation();resetImage();}}
                  sx={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    width:"20px",
                    height:"20px",
                    backgroundColor: 'red',
                    color: 'white', // ボタンの色を調整
                    ":hover":{backgroundColor:"#cc0000"}
                  }}
                  >
                  <CloseIcon sx={{fontSize:"12px"}}/>
                </IconButton>
              }
            </div>
            {loading == false && 
              <Button variant="contained" sx={{ minWidth:"48px", padding:"5px " + (ratio ? ratio * 8 : 8) + "px", fontSize: ratio ? ratio * 10 : 10, position: "absolute", right:-10, borderRadius: "10px", backgroundColor:"red", ":hover":{backgroundColor:"#cc0000"}}}
                onClick={() => {getUploadedFile(image)}}>
                <Typography sx={{fontSize:ratio ? ratio * 10 : 10, fontWeight:"bold"}}>切り取り</Typography>
                <ContentCutIcon sx={{ fontSize:ratio ? ratio * 14 : 14, marginLeft: "2px"}}/>
              </Button>
            }
          </div>
          
      }
      
      </Box>
    </label>
  );
}


export default Upload;