import { medium } from "@/interfaces/idea";
import axios from"axios"
import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import { Stack, Paper, Snackbar, Typography,Table, CircularProgress, TableSortLabel, TableContainer, TableHead, TableBody, TableRow, TableCell, Button, Box, Divider, Checkbox } from "@mui/material";
import { useQuery, useMutation, UseQueryResult, useQueryClient } from 'react-query';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz'
import PopupConfirmation from "./../components/popup/confirmation";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LimitedNormalOutlinedBordered from "./../components/Textfield/limited_normal_outlined_bordered";
import LimitedMultilineOutlinedBordered from "./../components/Textfield/limited_multiline_outlined_bordered";
import FlexSortSelector from "./../components/select/flexSort";
import { MyContext } from "./../routes/router";
import { simple } from "@/interfaces/recruitment";

const generateHeader = (before: number, heads:string[], after: number) => {
  return (
    <TableHead sx={{ zIndex:2, backgroundColor:"white", position:"sticky", top:0}}>
      <TableRow sx={{width:"100%", backgroundColor:"white"}}>
        {Array(before).fill(0).map((value:number, index:number) => {
          return (
            <TableCell align="left" sx={{paddingBottom:"20px"}}>
            </TableCell>
          )
        })}
        {heads.map((label: string, index: number,) => {
          return (
            <TableCell align="left" sx={{paddingBottom:"20px"}}>
              <Typography component="div" onClick={() => {}}sx={{ color:"grey", display: '-webkit-box', WebkitBoxOrient: 'vertical', wordWrap: 'break-word', WebkitLineClamp: 1, overflow: "hidden",}}>
                <Box sx={{  }}>
                  {label}
                </Box>
              </Typography> 
            </TableCell>
          )
        })}
        {Array(after).fill(0).map((value:number, index:number) => {
          return (
            <TableCell align="left" sx={{paddingBottom:"20px"}}>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

// ルーターによりURLで分岐
function Ideas () {
  const queryClient = useQueryClient();
  const { categories, recruits } = React.useContext(MyContext);

  // react-query 便利で良い。しかし凝縮性は悪いように思われるが...。使い回す場合はどうするのだ？
  const { isLoading, error, data:ideas }: UseQueryResult<medium[] | null, Error> = useQuery("ideas_medium", () => fetch(process.env.REACT_APP_API_URL+'/ideas/find/medium').then(res =>res.json()));
  const { data:user_creaters }: UseQueryResult<any[] | null, Error> = useQuery("user_creaters", () => fetch(process.env.REACT_APP_API_URL+'/users/findcreaters').then(res =>res.json()));
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [ selectedIdea, setSelectedIdea ] = useState<medium | null>(null)
  const [ showPopupConfirmation, setShowPopupConfirmation ] = useState<boolean>(false)
  const [ showSnackBar, setShowSnackBar ] = useState<boolean>(false)
  const [ userIdeaLikes, setUserIdeaLikes ] = useState<any>([])
  const [ editColumn, setEditColumn ] = useState<string>("")
  const [ editValue, setEditValue ] = useState<string>("")
  const [ creater_users, setCreaterUsers ] = useState<any>([])
  const selectedIdeaID = selectedIdea?.idea_id;

  const [ deletedTitle, setDeletedTitle ] = useState<string>("")

  const removeIdea = useMutation((postData : {idea_id: string}) => {
    return axios.post(process.env.REACT_APP_API_URL+'/ideas/remove/normal', postData)
  }, { onSuccess: () => { queryClient.invalidateQueries('ideas_medium'); } })

  const updateIDea = useMutation((postData : {idea_id: string, column: string, newValue: string}) => {
    return axios.post(process.env.REACT_APP_API_URL+'/ideas/update/onecolumn', postData)
  }, { onSuccess: (res) => { setEditColumn(""); queryClient.invalidateQueries('ideas_medium'); } })

  const findUserIdeaLikes = useMutation((postData : {idea_id: string}) => {
    return axios.post(process.env.REACT_APP_API_URL+'/user_idea_likes/find/findbyidea', postData)
  },{ onSuccess: (res) => { setUserIdeaLikes(res.data);},})

  useEffect(()=>{
    if(selectedIdea?.idea_id !== undefined){
      findUserIdeaLikes.mutate({idea_id: selectedIdea.idea_id})
    } 
  }, [selectedIdea])

  useEffect(()=>{
    setSelectedIdea(ideas?.filter((idea:medium) => idea.idea_id === selectedIdeaID)[0] ?? null)
  }, [ideas])

  useEffect(()=>{
    if(selectedIdea?.idea_id) setDeletedTitle(selectedIdea.title)
  }, [selectedIdeaID])


  const stackRef = useRef<HTMLDivElement>(null);
  const stackRef2 = useRef<HTMLDivElement>(null);
  
  const HEAD : {label: string, value:string}[] = [
    {label: "画像", value:"image_name"},
    {label: "題名", value:"title"},
    {label: "投稿者", value: "user_name"},
    {label: "いいね数", value: "num_of_likes"},
    {label: "発案日", value: "createdAt"},
    {label: "表示設定", value:"isnull_deletedAt"}
  ]

  return (
    <Stack padding={5} sx={{backgroundColor: "#F6F8FA"}}>
      {/* 上部グラフ類の描画 */}
      {/* <Stack width={"100%"} height={"300px"} spacing={2} direction={"row"} justifyContent={"space-between"}>
        <Stack width={"60%"} height={"100%"} alignItems={"center"} justifyContent={"center"} padding={"10px"} sx={{backgroundColor:"white", borderRadius:"20px"}}>
          <Typography sx={{ fontWeight:"bold", color:"lightgrey", fontSize:16, padding:"50px 10px 20px 10px"}}>
            IDEAに関するグラフ
          </Typography>
        </Stack>
        <Stack width={"35%"} height={"100%"} alignItems={"center"} justifyContent={"center"} padding={"10px"} sx={{backgroundColor:"white", borderRadius:"20px"}}>
          <Typography sx={{ fontWeight:"bold", color:"lightgrey", fontSize:16, padding:"50px 10px 20px 10px"}}>
            IDEAに関する円グラフ
          </Typography>
        </Stack>
      </Stack> */}

      {/* 下部1 アイデア一覧 削除 */}
      <Stack mt={1} width={"100%"} alignItems={"center"} justifyContent={"center"}  spacing={2} sx={{ position:"relative", backgroundColor:"white", borderRadius:"20px"}}>
        <Stack direction={"row"} justifyContent={"space-between"} width={"95%"}>
          <Typography sx={{ fontWeight:"bold", color:"#616161", fontSize:20, padding:"40px 10px 10px 10px"}}>
            Ideas
          </Typography>
        </Stack>
        <Divider sx={{width:"95%"}}/>
        <TableContainer sx={{ maxHeight: 600, alignItems:"center", position:"relative" }} component={Stack}>
          <Table size='small' sx={{ width:"95%"}}>
            {generateHeader(1,HEAD.map((head:{ label: string, value: string }, index: number,) => head.label),1)}
            <TableBody sx={{ }}>
              {ideas?.map((idea:medium) => {
                return(
                  <TableRow sx={{}}>
                    <TableCell align="left" sx={{maxWidth:"120px"}}>
                      <Checkbox/>
                    </TableCell>
                    {HEAD.map((head:{ label: string, value: string }, index: number,) => {
                      return (
                        <TableCell align="left" sx={{maxWidth:head.value === "title" ? "200px" : "100px"}}>
                          <Typography component="div" onClick={() => {
                            if(head.value === "title" || head.value === "image_name"){
                              setSelectedIdea(idea)
                              stackRef.current?.scrollIntoView({ behavior: 'smooth' });
                            }}} sx={{ cursor:head.value === "title" || head.value === "image_name" ? "pointer" : "arrow", display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, overflow: "hidden",}}>
                            <Box sx={{ color:head.value === "title" ? "royalblue" : "inherit", fontSize:"13px" }}>
                              {head.value === "image_name" && <img src={process.env.REACT_APP_PUBLIC_URL+"/images/"+idea.image_name} style={{ objectFit: 'cover', width:"40px", borderRadius:"15px"}}/>}
                              {head.value === "title" && idea.title}
                              {head.value === "user_name" && idea.user_name}
                              {head.value === "num_of_likes" && idea.num_of_likes}
                              {head.value === "createdAt" && format(toZonedTime(idea.createdAt, "UTC"),'M月d日 H時mm分')}
                              {head.value === "isnull_deletedAt" && (idea.deletedAt === null ? "表示" : "非表示")}
                            </Box>
                          </Typography> 
                        </TableCell>
                      )
                    })}
                    <TableCell align="left" sx={{maxWidth:"120px"}}>
                      <Button onClick={async () => {
                        setSelectedIdea(idea);
                        setShowPopupConfirmation(true) }}>削除</Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      {/* 下部2 アイデア詳細 編集 */}
      <Stack ref={stackRef} mt={7} width={"100%"} height={"600px"} alignItems={"center"} justifyContent={"flex-start"}  spacing={2} sx={{backgroundColor:"white", borderRadius:"20px"}}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"} width={"95%"}>
          <Typography sx={{ fontWeight:"bold", color:"#616161", fontSize:20, padding:"40px 10px 10px 10px"}}>
            Idea Details
          </Typography>
          <Typography sx={{ color:"#616161", fontSize:16, padding:"10px"}}>
            {selectedIdea?.title}
          </Typography>
        </Stack>
        <Divider sx={{width:"95%"}}/>

        {selectedIdea?.idea_id !== undefined &&
          <TableContainer sx={{ width:"95%", maxHeight: 600, alignItems:"flex-start", position:"relative" }} component={Stack}>
            <Table size='small' sx={{ width:"100%"}}>
              {generateHeader(0,["column", "value",],1)}
              <TableBody sx={{ }}>
                {Object.keys(selectedIdea ?? {})?.map((key:string, index:number) => {
                  return(
                    <TableRow sx={{}}>
                      <TableCell align="left" sx={{maxWidth: "80px"}}>
                        <Typography component="div" sx={{ }}>
                          <Box sx={{ color: "inherit", fontSize:"13px" }}>
                            {key}
                          </Box>
                        </Typography> 
                      </TableCell>
                      <TableCell align="left" sx={{maxWidth: "170px"}}>
                        <Typography component="div" sx={{wordWrap: 'break-word', whiteSpace:"pre-wrap"  }}>
                          <Box sx={{ color: "inherit", fontSize:"13px" }}>
                            {editColumn !== key ? 
                              (["createdAt", "deletedAt", "updatedAt"].includes(key) ? Object.values((selectedIdea ?? {}))[index] !== null ? format(toZonedTime(Object.values((selectedIdea ?? {}))[index], "UTC"),'yyyy年M月d日 H時mm分') : null :
                              ["apply_notification"].includes(key) ? String(Object.values((selectedIdea ?? {}))[index]) : Object.values((selectedIdea ?? {}))[index]) :
                              editColumn.includes("recruit_id") ? 
                                <FlexSortSelector labels={["null", ...recruits.map((recruit: simple) => recruit.title)]} sort_value={editValue} setSortValue={setEditValue} bold={false} />
                              :
                              editColumn.includes("apply_user_name") ? 
                                <FlexSortSelector labels={["null", ...user_creaters?.map((user: any) => user.user_name) ?? []]} sort_value={editValue} setSortValue={setEditValue} bold={false} />
                              :
                              editColumn.includes("apply_notification") ? 
                                <FlexSortSelector labels={["true", "false"]} sort_value={editValue} setSortValue={setEditValue} bold={false} />
                              :
                              editColumn.includes("category_name") ? 
                                <FlexSortSelector ids={["null", ...categories.map((category:{category_id:number, name:string}) => String(category.category_id+1))]} labels={["null", ...categories.map((category:{category_id:number, name:string}) => category.name)]} sort_value={editValue} setSortValue={setEditValue} bold={false} />
                              :
                                <LimitedMultilineOutlinedBordered rows={key === "description" ? 3 : 1} padding="7px 7px" fontSize="12px" value={editValue} placeholder="変更後の値を入力" setValue={setEditValue} finishFunc={() =>{if(buttonRef) buttonRef.current?.click();}} />
                            }
                          </Box>
                        </Typography> 
                      </TableCell>
                      <TableCell align="left" sx={{maxWidth:"50px"}}>
                        {["title", "recruit_id", "image_name", "description", "deadline", "tag1", "tag2", "tag3", "tag4", "tag5", "category_name1", "category_name2", "category_name3", "apply_user_name", "apply_notification"].includes(key) &&
                        <>
                          <Button onClick={() => {
                            if(editColumn === key){
                              setEditColumn("")
                              setEditValue("")
                            }else{
                              setEditColumn(key); 
                              // カテゴリ、recruit_idの微調整(クソ)
                              setEditValue( key.includes("category_name") ? String(categories.map((category:{category_id:number, name:string}) => category.name).indexOf(Object.values((selectedIdea ?? {}))[index])+2) :
                                               key.includes("recruit_id") ? recruits.filter((recruit: simple) => recruit.recruit_id === Object.values((selectedIdea ?? {}))[index])[0]?.title ?? ""
                                                                          : Object.values((selectedIdea ?? {}))[index])
                            }
                          }}>{editColumn === key ? "やめる" : "編集"}</Button>
                          {editColumn === key &&
                            <Button ref={buttonRef} onClick={() => {
                              let _editColumn = editColumn.includes("category_name") ? editColumn.replace("category_name", "category_id") : 
                                                editColumn.includes("apply_user_name") ? "apply_user_id" : editColumn
                              let _editValue = editValue==="null" ? "null" : 
                                               editColumn.includes("category_name") ? String(Number(editValue) -1) :
                                               editColumn.includes("recruit_id") ? recruits.filter((recruit: simple) => recruit.title === editValue)[0]?.recruit_id ?? "null" :
                                               editColumn.includes("apply_user_name") ? user_creaters?.filter((user: any) => user.user_name === editValue)[0]?.user_id ?? "null"
                                               : editValue
                              updateIDea.mutate({idea_id:selectedIdea?.idea_id ?? "", 
                                                 column:_editColumn, 
                                                 newValue:_editValue})}}>決定</Button>
                          }
                        </>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        }

      </Stack>

      {/* 下部3 アイデアへのいいねと出資 */}
      <Stack ref={stackRef2} width={"100%"}  mt={7}  height={"500px"} spacing={2} direction={"row"} justifyContent={"space-between"}>
        {/* いいね */}
        <Stack width={"40%"} height={"500px"} alignItems={"center"} justifyContent={"flex-start"}  spacing={2} sx={{backgroundColor:"white", borderRadius:"20px"}}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"} width={"95%"}>
            <Typography sx={{ fontWeight:"bold", color:"#616161", fontSize:18, padding:"20px 10px 0px 20px"}}>
              Likes
            </Typography>
          </Stack>
          <Divider sx={{width:"95%"}}/>

          {(userIdeaLikes?.length ?? 0) > 0 && 
            <TableContainer sx={{ width:"95%", maxHeight: 600, alignItems:"flex-start", position:"relative" }} component={Stack}>
              <Table size='small' sx={{ width:"100%"}}>
                {generateHeader(1,["user_name", "createdAt",],0)}
                <TableBody sx={{ }}>
                  {userIdeaLikes?.map((like:any, index:number) => {
                    if(like.deletedAt !== null){return null;}
                    return(
                      <TableRow sx={{}}>
                        <TableCell align="left" sx={{maxWidth: "80px"}}>
                          <FavoriteIcon sx={{color:"#ff5252", fontSize:"20px"}}/>
                        </TableCell>
                        <TableCell align="left" sx={{maxWidth: "80px"}}>
                          <Typography component="div" sx={{ }}>
                            <Box sx={{ color: "inherit", fontSize:"13px" }}>
                              {like.user_name}
                            </Box>
                          </Typography> 
                        </TableCell>
                        <TableCell align="left" sx={{maxWidth: "170px"}}>
                          <Typography component="div" sx={{wordWrap: 'break-word',  }}>
                            <Box sx={{ color: "inherit", fontSize:"13px" }}>
                              {format(toZonedTime(like.createdAt, "UTC"),'M/d H:mm')}
                            </Box>
                          </Typography> 
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Stack>

        {/* 出資 */}
        <Stack width={"60%"} height={"500px"} alignItems={"center"} justifyContent={"flex-start"}  spacing={2} sx={{backgroundColor:"white", borderRadius:"20px"}}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"} width={"95%"}>
            <Typography sx={{ fontWeight:"bold", color:"#616161", fontSize:18, padding:"20px 10px 0px 20px"}}>
              Investments
            </Typography>
          </Stack>
          <Divider sx={{width:"95%"}}/>
        </Stack>
      </Stack>

      <PopupConfirmation title={selectedIdea?.title ?? ""} open={showPopupConfirmation} handleClose={()=>{setShowPopupConfirmation(false)}} deleteFunc={async () => {
          await removeIdea.mutate({idea_id:selectedIdea?.idea_id ?? ""})
          setShowSnackBar(true);
      }}/>

      <Snackbar
        open={showSnackBar}
        autoHideDuration={6000}
        onClose={()=>{setShowSnackBar(false)}}
        message={"『"+(deletedTitle) +"』 が正常に削除されました"}
      />

    </Stack>
  );
}
  
  export default Ideas;
  