// PopupComponent
import React, { useState, useEffect, useContext, useRef } from 'react';
import Box from '@mui/material/Box';
import { InputAdornment, IconButton, Typography, Button, Modal, Stack, TextField, Card, Avatar } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';
import { useSearchParams } from "react-router-dom";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {MyContext} from "../../routes/router"
import TagsTextField from '../Textfield/tags';

import LimitedNormalOutlined from '../Textfield/limited_normal_outlined';
import LimitedMultilineOutlined from '../Textfield/limited_multiline_outlined';
import { medium } from '../../interfaces/recruitment';
import LimitedCheckboxCategories from '../CheckBox/limited_categories';
import Upload, { compressImage } from '../Crop/upload';
import Popup from '../Crop/popup';
import FlexSortSelector from '../select/flexSort';
import { UseQueryResult, useMutation, useQueries, useQuery } from 'react-query';
import FlexSortSelector2 from '../select/flexSort2';
import axios from 'axios';
import MoreButton from '../buttons/more';
import LimitedMultilineOutlinedBordered from '../Textfield/limited_multiline_outlined_bordered';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
[`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 420,
    fontSize: theme.typography.pxToRem(24),
    border: '1px solid #dadde9',
},
}));
  
const header = (title: string, required:boolean | null, help:string) => {
    return(
        <Stack direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"flex-start"} spacing={2}>
        <Typography  component="div" sx={{letterSpacing: '2px'}}>
            <Box textAlign={"center"} sx={{ borderRadius:"13px", width:"50px", display:required === null ? "none" : "block", backgroundColor:required ? "#383E86" : "#707070", color: "white", fontSize:13, fontWeight: 'bold', }}>
            {required === true ? "必須" : "任意"}
            </Box>
        </Typography>
        <Typography  component="div" sx={{letterSpacing: '2px'}}>
            <Box textAlign={"center"} sx={{ borderRadius:"13px", width:"100%", color: "#5E5E5E", fontSize:17, fontWeight: 'bold', }}>
            {title}
            </Box>
        </Typography>
        <HtmlTooltip placement="right" title={
            <React.Fragment>
            <Typography fontSize={10} color="inherit">
                {help.split('\n').map((sentence, index) => (
                <React.Fragment key={index}>
                    {sentence}
                    {index !== help.split('\n').length - 1 && <br />}
                </React.Fragment>
                ))}
            </Typography>
            </React.Fragment>
        }>
            <HelpOutlineIcon sx={{color:"#383E86", fontSize: 20, cursor:"pointer"}} />
        </HtmlTooltip>
        </Stack>
    )
}

// Propsの型を定義
interface Props {
    resetInformation: () => void;
    recruitID?: string | null;
    isMobile?: boolean;
    title: string;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    setTitleHoverd: React.Dispatch<React.SetStateAction<boolean>>;
    selectedUserID: string;
    setSelectedUserID: React.Dispatch<React.SetStateAction<string>>;
    setSelectedUserIDHovered: React.Dispatch<React.SetStateAction<boolean>>;
    deadline: string;
    setDeadline: React.Dispatch<React.SetStateAction<string>>;
    setDeadlineHovered: React.Dispatch<React.SetStateAction<boolean>>;
    benefit_num: string;
    setBenefitNum: React.Dispatch<React.SetStateAction<string>>;
    setBenefitNumHovered: React.Dispatch<React.SetStateAction<boolean>>;
    benefit_description: string;
    setBenefitDescription: React.Dispatch<React.SetStateAction<string>>;
    setBenefitDescriptionHovered: React.Dispatch<React.SetStateAction<boolean>>;
    categories: (string | null)[];
    setCategories: React.Dispatch<React.SetStateAction<(string | null)[]>>;
    setCategoriesHoverd: React.Dispatch<React.SetStateAction<boolean>>;
    midjourney_text: string;
    setMidjourneyText: React.Dispatch<React.SetStateAction<string>>;
    description: string;
    setDescription: React.Dispatch<React.SetStateAction<string>>;
    setDescriptionHoverd: React.Dispatch<React.SetStateAction<boolean>>;
    display_base64: string;
    setDisplayBase64: React.Dispatch<React.SetStateAction<string>>;
    setDisplayBase64Hoverd: React.Dispatch<React.SetStateAction<boolean>>;
}

interface RecruitResponse {
    data: medium | null;
    isValid: boolean;
    setIsValidData: React.Dispatch<React.SetStateAction<boolean>>;
  }


const IdeaForm :React.FC<Props> = ({ resetInformation, recruitID, isMobile, title, setTitle, setTitleHoverd, selectedUserID, setSelectedUserID, setSelectedUserIDHovered, deadline, setDeadline, setDeadlineHovered, benefit_num, setBenefitNum, setBenefitNumHovered, benefit_description, setBenefitDescription, setBenefitDescriptionHovered, categories, setCategories, setCategoriesHoverd, description, setDescription, setDescriptionHoverd, display_base64, setDisplayBase64, setDisplayBase64Hoverd, midjourney_text, setMidjourneyText }) => {
    
    const { categories: allCategories, socket, user } = React.useContext(MyContext);
    const { data:user_creaters }: UseQueryResult<any[] | null, Error> = useQuery("user_creaters", () => fetch(process.env.REACT_APP_API_URL+'/users/findcreaters').then(res =>res.json()));

    const stackRef = useRef<HTMLInputElement | null>(null);
    const [file_base64, setFileBase64] = useState<string>("");
    const [edit_file_base64, setEditFileBase64] = useState<string>("");

    const [selectedUser_error, setSelectedUserError] = React.useState<boolean>(false);
    const [deadline_error, setDeadlineError] = React.useState<boolean>(false);
    const [benefit_num_error, setBenefitNumError] = React.useState<boolean>(false);
    const [benefit_description_error, setBenefitDescriptionError] = React.useState<boolean>(false);
    const [title_error, setTitleError] = React.useState<boolean>(false);
    const [categoriesError, setCategoriesError] = useState<boolean>(false);
    const [description_error, setDescriptionError] = React.useState<boolean>(false);
    const [image_error, setImageError] = React.useState<boolean>(false);

    const [midjouney_error, setMidjourneyError] = React.useState<boolean>(false);

    const [crop_open, setCropOpen] = React.useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);

    const saveRecruitment = useMutation((postData : {title: string, user_id: string, benefit_num:string, benefit_description:string, categories: (string|null)[], description: string, display_base64: string, deadline: string}) => {
        const formData = new FormData();
        formData.append('title', postData.title);
        formData.append('user_id', postData.user_id);
        formData.append('description', postData.description);
        formData.append('deadline', postData.deadline);
        formData.append('benefit_num', postData.benefit_num);
        formData.append('benefit_description', postData.benefit_description);
        if (postData.categories) {
            formData.append('category1', postData.categories[0] || '');
            formData.append('category2', postData.categories[1] || '');
            formData.append('category3', postData.categories[2] || '');
        }
        // ファイルデータを追加
        if (postData.display_base64) {
            const byteString = atob(postData.display_base64.split(',')[1]);
            const mimeString = postData.display_base64.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([ab], {type: mimeString});
            
            formData.append('file', blob, 'image.png');  // ファイル名は適宜変更
        }
        
        return axios.post(process.env.REACT_APP_API_URL + '/recruitments/save/normal', formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        });
    }, { onSuccess: (res) => { resetInformation(); window.location.reload() } });
    

    // 投稿する前の各項目のエラーチェック
    const checkRequiredValue = () => {
        let flag = false
        if(title.trim() === ""){setTitleError(true); flag=true}
        if(categories.length === 0) {setCategoriesError(true); flag=true}
        if(description.trim() === "") {setDescriptionError(true); flag=true}
        if(display_base64 === "") {setImageError(true); flag=true}
        if(benefit_num === "") {setBenefitNumError(true); flag=true}
        if(benefit_description === "") {setBenefitDescriptionError(true); flag=true}
        if(deadline === "") {setDeadlineError(true); flag=true}
        if(selectedUserID === "") {setSelectedUserError(true); flag=true}
        if(flag){
            scrollToTop();
            return false;
        }else{
            return true;
        }
    }

    // midjouneyに画像生成の要求を行う
    const midjouneyImagine = (text:string, login_func:() => void, success_func: (uri:string) => void) => {
        axios.post(process.env.REACT_APP_API_URL+'/midjouney/imagine', {text: text}, {
            withCredentials: true, // これ必須だった
          }).then((res) => {
                if (res.status === 200) {
                    if(res.data === "login require"){
                        login_func()
                    }else{
                        success_func(res.data.uri);
                    }
                }else{
                    console.log("failed")
                }
            }).catch((err) => {
                console.log(err);
            });       
    }


    const scrollToTop = () => {
        const stackElement = stackRef.current;
        if(stackElement !== null){
            stackElement.scrollTo({
                top: 0,
                behavior: 'smooth', // スムーズなスクロール
            });
        }
    };

    useEffect(() => {
        if(title.trim() !== "") setTitleError(false);
    }, [title]);
    useEffect(() => {
        if(selectedUserID !== ""){setSelectedUserError(false);}
    }, [selectedUserID]);
    useEffect(() => {
        if(deadline !== ""){setDeadlineError(false);}
    }, [deadline]);
    useEffect(() => {
        if(benefit_description !== ""){setBenefitDescriptionError(false);}
    }, [benefit_description]);
    useEffect(() => {
        if(benefit_num !== ""){setBenefitNumError(false);}
    }, [benefit_num]);
    useEffect(() => {
        if(categories.length > 0) setCategoriesError(false);
    }, [categories]);
    useEffect(() => {
        if(description.trim() !== "") setDescriptionError(false);
    }, [description]);
    useEffect(() => {
        if(file_base64 !== ""){setImageError(false);}
    }, [file_base64]);
    useEffect(() => {
        if(midjourney_text) setMidjourneyError(false);
    }, [midjourney_text]);

    useEffect(() => {
      if (socket !== null) {
        socket.off('midjourney/progress');
  
        socket.on('midjourney/progress', (data:any) => {
          // 何らかのやべーエラーが発生しているとき
          if(data.uri === "serious error is occured"){
            setMidjourneyError(true)
          }
          else if(data.uri !== null || data.uri !== undefined || data.uri !== ""){
            fetchAndCompressImage(data.uri);
          }
          if(data.progress !== (null || undefined || "")){
            setProgress(parseInt(data.progress.replace('%', '')))
          }
        });
      }
    }, [socket]);

    useEffect(() => {
        setFileBase64(display_base64)
      }, []);

    // URIから画像を取得して表示する（圧縮版）
    const fetchAndCompressImage = async (uri:string) => {
    try {
        // URIから画像を取得
        const response = await fetch(uri);
        const blob = await response.blob();

        // BlobをFileに変換
        const fileName = 'image.jpg'; // 任意のファイル名を指定
        const fileType = 'image/jpeg'; // 画像のMIMEタイプを指定
        const file = new File([blob], fileName, { type: fileType });

        compressImage(file, (compressedFile:File)=>{
            // 圧縮された画像をBase64に変換
            const reader = new FileReader();
            reader.onloadend = () => {
            const base64data = reader.result;
            if (typeof base64data === "string") {
                setDisplayBase64(base64data);
                setFileBase64(base64data);
            }
            };
            reader.readAsDataURL(compressedFile);
        })
    } catch (error) {
        console.error('Error fetching or compressing image:', error);
    }
    };


  return (
    <Stack ref={stackRef} direction={"column"} alignItems={"center"} sx={{ overflowY:"auto"}}>
        <Stack mt={1} mb={1} alignItems={"center"} sx={{width: "100%",  backgroundColor:"transparent"}}>
            <Stack alignItems={"center"} sx={{width:isMobile? "80%" : "75%"}}>

            {/* IDEA題名 */}
            <Stack mt={2} spacing={0.5} justifyContent={"flex-start"} sx={{width: "100%"}}>
                {header("IDEA募集題名", true, "わかりやすく、オリジナリティ溢れる\n題名を30字以内で考えましょう!\n")}
                <div onMouseEnter={() => setTitleHoverd(true)} onMouseLeave={() => setTitleHoverd(false)}>
                    <LimitedNormalOutlined error={title_error} placeholder='IDEA募集の題名を記入してください' value={title} setValue={setTitle} max={30}/>
                </div>
            </Stack>

            {/* ユーザー */}
            <Stack mt={2} mb={2} spacing={1.5} justifyContent={"flex-start"} sx={{width: "100%"}}>
                {header("ユーザー", true, "")}
                <div onMouseEnter={() => setSelectedUserIDHovered(true)} onMouseLeave={() => setSelectedUserIDHovered(false)}>
                    <FlexSortSelector2 error={selectedUser_error} values={user_creaters?.map((user:any) => user.user_id) ?? []} labels={user_creaters?.map((user:any) => user.user_name) ?? []} sort_value={selectedUserID} setSortValue={setSelectedUserID}/>
                </div>
            </Stack>

            {/* 締切日 */}
            <Stack mt={2} mb={2} spacing={1.5} justifyContent={"flex-start"} sx={{width: "100%"}}>
                {header("締切日", true, "")}
                <div onMouseEnter={() => setDeadlineHovered(true)} onMouseLeave={() => setDeadlineHovered(false)}>
                    <LimitedMultilineOutlinedBordered error={deadline_error} type='date' value={deadline} setValue={setDeadline} placeholder='締切日を記入' />
                </div>
            </Stack>

            {/* 売上還元率 */}
            <Stack mt={2} mb={2} spacing={1.5} justifyContent={"flex-start"} sx={{width: "100%"}}>
                {header("売上還元率", true, "")}
                <div onMouseEnter={() => setBenefitNumHovered(true)} onMouseLeave={() => setBenefitNumHovered(false)}>
                    <LimitedMultilineOutlinedBordered error={benefit_num_error} type='number' value={benefit_num} setValue={setBenefitNum} placeholder='売上還元率(%)を記入' />
                </div>
            </Stack>

            {/* IDEA採用特典 */}
            <Stack mt={2} mb={2} spacing={1.5} justifyContent={"flex-start"} sx={{width: "100%"}}>
                {header("IDEA採用特典詳細", true, "")}
                <div onMouseEnter={() => setBenefitDescriptionHovered(true)} onMouseLeave={() => setBenefitDescriptionHovered(false)}>
                    <LimitedMultilineOutlinedBordered error={benefit_description_error} rows={3} value={benefit_description} setValue={setBenefitDescription} placeholder='IDEA採用特典の詳細を記入' />
                </div>
            </Stack>


            {/* カテゴリ */}
            <Stack mt={0.5} spacing={0.5} justifyContent={"flex-start"} sx={{width: "100%"}}>
                {header("カテゴリ", true, "適切なカテゴリを指定することで、検索に引っかかりやすくなります！")}
                <div onMouseEnter={() => setCategoriesHoverd(true)} onMouseLeave={() => setCategoriesHoverd(false)}>
                    <LimitedCheckboxCategories error={categoriesError} max={3} setCategories={setCategories} 
                        categories={categories}
                        
                        defaultCategories={categories}
                        disabled={false}
                        fontSize='14px'
                        padding="0px 0px"/>
                </div>
            </Stack>
            
            {/* IDEA説明(詳細) */}
            <Stack mt={0.5} spacing={0.5} justifyContent={"flex-start"} alignItems={"center"} sx={{width: "100%"}}>
                {header("IDEA募集詳細", true, "あなたのIDEA募集を300字以内で説明しましょう!")}
                <div style={{width:"100%"}} onMouseEnter={() => setDescriptionHoverd(true)} onMouseLeave={() => setDescriptionHoverd(false)}>
                    <LimitedMultilineOutlined error={description_error}  placeholder='あなたのIDEA募集を300字以内で説明しましょう!' rows={10} value={description} setValue={setDescription} max={300}/>
                </div>
            </Stack>

            {/* AI画像生成 */}
            <Stack mt={4} spacing={1} justifyContent={"flex-start"} alignItems={"center"} sx={{width: "100%"}}>
                {header("AI画像生成", false, "100文字以内でアイデア画像について説明をしてください")}
                <LimitedMultilineOutlined error={midjouney_error} rows={3} placeholder='例. カバンにもなるし、棚にもなる鞄を出力してください' value={midjourney_text ? midjourney_text : ""} setValue={setMidjourneyText} max={100}/>
                {midjouney_error && 
                    <Typography component="div" width={"100%"} textAlign={"right"} sx={{margin:"5px auto", letterSpacing: '0px'}}>
                        <Box sx={{  color: "red", fontSize:10, fontWeight: 'medium',  }}>
                            ※ 禁止されている単語が含まれている可能性があります。
                        </Box>
                        <Box sx={{  color: "red", fontSize:10, fontWeight: 'medium',  }}>
                            ※ しばらく利用を停止している場合があります。少し時間をおいてから再生成をしてください。
                        </Box>
                    </Typography>
                }
                <Button disabled={loading || midjourney_text?.trim() == "" || midjouney_error} variant='contained' sx={{ width:"100%", marginBottom:"20px", fontSize: "14px", borderRadius: "40px", backgroundColor: '#383E86', ':hover': {backgroundColor: '#383E86',opacity: 0.9}}} 
                onClick={() => {
                    setProgress(0)
                    setFileBase64("")
                    setEditFileBase64("")
                    setDisplayBase64("")
                    setLoading(true);
                    midjouneyImagine(midjourney_text, ()=>{}, (uri:string)=>{ setLoading(false); fetchAndCompressImage(uri); })
                    }}>AI画像生成</Button>
            </Stack>

            {/* IDEA画像 */}
            <Stack mt={6} spacing={1} justifyContent={"flex-start"} sx={{position:"relative", width: "100%"}}>
                {header("IDEA画像", true, "あなたのアイデアをわかりやすく表した画像を投稿しましょう!\nIDEA説明から自動で画像を生成することもできます!")}
                {/* 通常時の下線 と hover時の下線 を消す */}
                
                <div onMouseEnter={() => setDisplayBase64Hoverd(true)} onMouseLeave={() => setDisplayBase64Hoverd(false)}>
                    <Upload
                        error={image_error}
                        resetImage={() => {setEditFileBase64(""); setFileBase64(""); setDisplayBase64("")}}
                        image={file_base64}
                        setImage={setFileBase64}
                        display_image={display_base64}
                        loading={loading}
                        progress={progress}
                        getUploadedFile={(image:string) => {
                            setEditFileBase64(image);
                            setCropOpen(true);
                    }}/>
                </div>
                    <Popup
                        open={crop_open}
                        handleClose={() => {setCropOpen(false)}}
                        image={edit_file_base64}
                        getCroppedFile={(image:string) => {
                            setDisplayBase64(image);
                            setCropOpen(false);
                        }}
                    />
            </Stack>

            {/* 投稿するボタン */}
            <div style={{width: "100%", marginTop:"70px", marginBottom:"50px"}}>
                <MoreButton fontSize={"16px"} width='100%' height='50px' text='掲載する' borderRadius='5px'
                onClick={() => {
                    if(checkRequiredValue()){
                        saveRecruitment.mutate({title: title, user_id: selectedUserID, benefit_num:benefit_num, benefit_description:benefit_description, categories:categories, description:description, display_base64:display_base64, deadline:""})
                    }
                }} />
            </div>

            </Stack>
        </Stack>
    </Stack> 
  );
};

export default IdeaForm;