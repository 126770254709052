import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CropperDemo from "./cropper";
import { Dispatch, SetStateAction } from 'react';

import { Stack, Typography, Box, ButtonGroup } from '@mui/material';

// Propsの型を定義
interface Props {
  image: string;
  handleClose: () => void;
  open: boolean;
  getCroppedFile: (file: string) => void;
}


const Popup :React.FC<Props> = ({ open, image, handleClose, getCroppedFile }) => {

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">

        <DialogTitle id="alert-dialog-title" sx={{color: "#383E86", fontWeight:"bold", fontSize:"16px"}}>
          画像の切り取り
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CropperDemo
              src={image}
              getCroppedFile={getCroppedFile}/>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Popup;