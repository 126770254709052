import React from 'react';
import Box from '@mui/material/Box';
import { Typography, Button } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';

interface Props {
    num_of_likes: number;
    onClick?: () => void;
    fontSize?: number;
    disabled?: boolean;
    isMobile?: boolean;
    padding?: string;
    spacing?: number;
    liked?: boolean;
}


// ハートマークつきの いいねボタン
const LikeButton: React.FC<Props> = (props) => {
    let fontSize = props.isMobile ? 20 : 24;
    if(props.fontSize != (null || undefined) ){
        fontSize = props.fontSize;
    }
    return (
        <Button fullWidth disabled={props.disabled == true ? true : false} variant='contained' sx={{ border: "1px solid #FE595B", borderRadius: "0px", padding:props.isMobile ? "5px"  :props.padding ? props.padding : "20px",  backgroundColor: props.liked == true ? "#FE595B" : "white", ":hover": { backgroundColor: props.liked == true ? "#FE595B" : "#FFE3E3", opacity: 0.8 } }} 
                onClick={() => {
                    if(props.onClick !== (undefined)){
                        props.onClick()
                    }
                }}>

            {/* ハートマーク */}
            <FavoriteIcon sx={{ fontSize:fontSize, color:props.disabled == true ? "lightgrey" : props.liked == true ? "white" : "#FE595B", backgroundColor: "transparent", marginRight: props.spacing ? props.spacing * 4 + "px" : "20px" }} />

            {/* いいね */}
            <Typography component="div">
                <Box sx={{ color: props.disabled == true ? "lightgrey" : props.liked == true ? "white" : "#FE595B", fontSize: fontSize, fontWeight: 'medium',  }}>いいね</Box>
            </Typography>

            {/* いいねの数字表記 */}
            <Typography component="div">
                <Box sx={{ backgroundColor:props.disabled == true ? "lightgrey" : props.liked == true ? "white" : "#FE595B", marginLeft: props.spacing ? props.spacing * 4 + "px" : "20px", borderRadius: "8px", padding:props.isMobile ? "3px 8px" : "3px 10px", color:props.disabled == true ? "white" : props.liked == true ? "#FE595B" : "white", fontSize:props.isMobile ? fontSize - 2 : fontSize - 6, fontWeight: 'medium',  }}>{props.num_of_likes}</Box>
            </Typography>
        </Button>
    );
};

export default LikeButton;
