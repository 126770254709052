import React, { useState, useEffect, ChangeEvent, useContext } from 'react';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import { Grid, FormGroup, Checkbox, Card, Button, TextField, Typography,InputAdornment, Box } from '@mui/material';
import { useSearchParams } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import FormControlLabel from '@mui/material/FormControlLabel';
import { simple } from './../../interfaces/category';
import { MyContext } from './../../routes/router';

interface Props {
    setCategories: (value:(string | null)[]) => void
    max: number;
    defaultCategories: (string | null)[]
    categories: (string | null)[];
    disabled: boolean
    error?: boolean
    fontSize?: string
    padding?:string
    isMobile?: boolean;
}

const FormController = (onChange:(event: ChangeEvent<HTMLInputElement>, checked: boolean) => void, label:string, selectedValues:(string | null)[], max:number, defaultCategories:(string | null)[], special_disabled:boolean, fontSize?:string) => {
    return(
        <FormControlLabel value={label}  sx={{ '& .MuiSvgIcon-root': { fontSize: fontSize ? fontSize : 16 } }} control={<Checkbox checked={special_disabled ? defaultCategories.includes(label) : selectedValues.includes(label)} disabled={special_disabled ? true :selectedValues.length < max ? false : !selectedValues.includes(label)} onChange={onChange} />} 
                          label={<span style={{ fontSize: fontSize ? fontSize : 18 }}>{label}</span>} />
    )
}


// 投稿ボタン
const LimitedCheckboxCategories: React.FC<Props> = (props) => {

  const [selectedValues, setSelectedValues] = useState<(string | null)[]>(props.defaultCategories);

  React.useEffect(() => {
    setSelectedValues(props.defaultCategories)
  }, []);

  const handleSelectionChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { value } = event.target;
    if(selectedValues.length >= props.max && !selectedValues.includes(value)) return;
    const index = selectedValues.indexOf(value);
    if (index === -1) {
      setSelectedValues([...selectedValues, value]); // Add to array if not already selected
      props.setCategories([...selectedValues, value])
    } else {
      const updatedValues = [...selectedValues];
      updatedValues.splice(index, 1); // Remove from array if already selected
      setSelectedValues(updatedValues);
      props.setCategories(updatedValues)
    }
  };


  const { categories }  = useContext(MyContext);

  return (
    <Stack>
    <FormControl>
        <FormGroup row sx={{ justifyContent:"flex-start", borderRadius:"20px", padding: props.padding ? props.padding : "20px", border: props.error ? "1.5px solid red" : "none"}} >
          {categories.map((category:{category_id:number, name:string}, index:number) => {
              return(
                FormController(handleSelectionChange, category.name, selectedValues, props.max, props.defaultCategories,props.disabled, props.fontSize)
              )
          })}
        </FormGroup>
    </FormControl>
            <Stack direction={"row"} justifyContent={"flex-end"}>
                <Typography sx={{fontSize: 14, color: selectedValues.length >= props.max ? "#e60000" : "grey"}}>
                    
                  {props.disabled ? 
                    props.defaultCategories.length + "/" + props.max
                  :
                    selectedValues.length + "/" + props.max
                  }
                </Typography>
            </Stack>

    </Stack>

  );
}

export default LimitedCheckboxCategories;
