import { Box, Typography, Card, Stack, Avatar} from "@mui/material";
import React from "react";
import { useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import UserIcon from "./icon";


export interface IdeaCardProps {
    // 優先順位順
    profile_image_name?: string | null | undefined;
    profile_image_uri?: string | null | undefined;

    user_name: string;
    onClick: () => void;
    border?: boolean | null;
    ratio?: number;
    cursor?: boolean
    display?:boolean

    arrow?:boolean;

    isMobile?: boolean;
}

const UserCard: React.FC<IdeaCardProps> = (props: IdeaCardProps) => {

    let ratio = 1;
    if(props.ratio != (null || undefined)){
        ratio = props.ratio;
    }

    if(props.display == false){
        return null;
    }

    return (
    <Stack direction={"row"} alignItems={"center"} onClick={() => {props.onClick()}} justifyContent={"space-between"} sx={{cursor: props.cursor == false ? "content" : "pointer", width: "100%", height:props.isMobile ? 30*ratio : 90*ratio, borderBottom:props.border ? "1px solid lightgrey" : "none", borderTop:props.border ? "1px solid lightgrey" : "none"}}>

        <Stack direction={"row"} alignItems={"center"} spacing={props.isMobile ? 1 : 2}>
            <UserIcon ratio={ratio} radius={23} onClick={() => {window.location.href="/"}} 
                      user_name={props.user_name} profile_image_name={props.profile_image_name} profile_image_uri={props.profile_image_uri}
                      isMobile={props.isMobile}/>
            <Typography  component="div" width={"100%"} sx={{letterSpacing: '0px'}}>
                <Box sx={{color: "#2B2B2B", fontSize:props.isMobile ? 12 : 24*ratio, fontWeight: 'medium', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp:1, overflow: "hidden"}}>
                    {props.user_name}
                </Box>
            </Typography>
        </Stack>
        {!props.isMobile && props.arrow !== false && <KeyboardArrowRightIcon sx={{color:"#9D9D9D", fontSize:48*ratio}}/>}
    </Stack>
  );
};

export default UserCard;
