import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import Skeleton from "@mui/material/Skeleton";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import ContentCutIcon from '@mui/icons-material/ContentCut';
import { Stack, Button } from '@mui/material';


import "cropperjs/dist/cropper.css";

// Propsの型を定義
interface Props {
  src: string;
  getCroppedFile: (file: string) => void;
}


const CropperDemo :React.FC<Props> = ({ src, getCroppedFile }) => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const [loading, setLoading] = useState(true);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);

  const handleClick = () => {
    const imageElement = cropperRef?.current;
    if (imageElement) {
      const cropper = (imageElement as any).cropper; // any型を使用している場合
      const img = cropper.getCroppedCanvas().toDataURL();
      getCroppedFile(img);
    }
  };
  return (
    <Stack style={{ width: '100%', height: '100%', alignItems:"center" }}> 
      {/* <Box display={"flex"} justifyContent={"flex-end"} mb={1}>
          <Button variant="contained" sx={{ margin: '0 5px', borderRadius:"10px" }} onClick={rotate}>回転</Button>
          <Button variant="contained" sx={{ margin: '0 5px', borderRadius:"10px" }} onClick={() => flip("h")}>横反転</Button>
          <Button variant="contained"  sx={{ margin: '0 5px', borderRadius:"10px" }} onClick={() => flip("v")}>縦反転</Button>
      </Box> */}
      <Cropper
        zoomable={false}
        src={src}
        autoCropArea={0.5}
        style={{ height: "100%", width: "100%" }}
        aspectRatio={16 / 16}
        guides={false}
        dragMode="crop"
        // autoCropArea={1} // 画像がはみ出さないようにする
        viewMode={1} // 透明な領域が存在しないようにする
        ready={() => {
          setLoading(false);
        }}
        ref={cropperRef}
      />
      <Button
        sx={{
          marginTop:"20px",
          width:"300px",
          fontSize: 16, 
          borderRadius: "20px", backgroundColor:"red", ":hover":{backgroundColor:"#cc0000"}
        }}
        onClick={() => {handleClick()}}
        autoFocus
        variant="contained"
      >
        切り取る
        <ContentCutIcon sx={{marginLeft: "10px"}}/>
      </Button>
    </Stack>
  );
}

export default CropperDemo;