import { Box, Typography, Card, Stack, Avatar, Button, Divider, TextField, InputAdornment } from "@mui/material";
import React from "react";
import { useState, useRef, useContext } from 'react';
import TagIcon from '@mui/icons-material/Tag';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {MyContext} from "../../routes/router";
import { format } from 'date-fns';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import ImageIcon from '@mui/icons-material/Image';
import { complicated, medium } from "../../interfaces/idea";
import UserCard from "../User/card";
import { toZonedTime } from "date-fns-tz";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import PostIdeaButton from "../buttons/postIdea";
import LikeButton from "../buttons/like";
import { UseQueryResult, useQuery } from "react-query";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 25,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: theme.palette.mode === 'light' ? '#383E86' : '#308fe8',
  },
}));


export interface Props {
  title: string;
  title_hovered?: boolean;
  selectedUserID: string;
  selectedUserID_hovered: boolean;
  deadline: string;
  deadline_hovered?: boolean;
  benefit_description: string;
  benefit_description_hovered?: boolean;
  description: string;
  description_hovered?: boolean;
  categories: (string | null)[];
  categories_hovered?: boolean;
  display_base64: string;
  display_base64_hovered?: boolean;

  contentSize: number
  titleSize: number
  spacing: number

  view?: string | null;
}

const ApplyView: React.FC<Props> = (props: Props) => {
  const { data:user_creaters }: UseQueryResult<any[] | null, Error> = useQuery("user_creaters", () => fetch(process.env.REACT_APP_API_URL+'/users/findcreaters').then(res =>res.json()));
  
  return (
    <Stack direction={"row"} sx={{position:"relative", width: "100%", height:"100%", overflowY:"auto"}} justifyContent={"center"}>
      <Stack mt={6} width={"100%"} alignItems={"center"} >
        
        <Stack direction={"row"} spacing={5} width={"85%"} justifyContent={"center"}>

          {/* 左側のコンポーネント */}
          <Stack width={"55%"}  height={"100%"} justifyContent={"flex-start"} spacing={2} sx={{}}>
            {props.display_base64.trim() === "" ?
                <Box sx={{
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  padding: '80px 20px',
                  textAlign: 'center',
                  backgroundColor: props.display_base64_hovered ? "#eeeeee" : "#F6F6F6",
                }}>
                  <Typography sx={{fontSize:16, fontWeight:"bold", color:"#ACACAC"}}>
                    まだ画像がありません
                  </Typography>
                </Box>
            :
              <img src={props.display_base64} style={{ verticalAlign: "top", width: "100%", objectFit: "cover" }}/>
            }
            
            
            {/* IDEA募集 詳細説明 */}
            <Stack spacing={3} sx={{}}>
              <Stack width={"90%"} sx={{backgroundColor: props.selectedUserID_hovered ? "#eeeeee" : "transparent",}}>
                <UserCard 
                  user_name={user_creaters?.filter((user:any) => user.user_id === props.selectedUserID)[0]?.user_name} 
                  onClick={()=>{}}
                  cursor={false}
                  // 優先順位順
                  profile_image_name={user_creaters?.filter((user:any) => user.user_id === props.selectedUserID)[0]?.profile_image_name}
                  profile_image_uri={user_creaters?.filter((user:any) => user.user_id === props.selectedUserID)[0]?.profile_image_uri}
                  ratio={0.5}/>
              </Stack>

              <Typography  component="div" width={"100%"} sx={{letterSpacing: '1px', }}>
                <Box sx={{color: "#363636", fontSize:props.contentSize+4, fontWeight: 'bold', }}>
                  IDEA募集説明
                </Box>
              </Typography>
              <Typography  component="div" width={"100%"} sx={{letterSpacing: '1px', backgroundColor: props.description_hovered ? "#eeeeee" : "none", borderRadius: "4px"}}>
                <Box sx={{whiteSpace: "pre-wrap", color: "#363636", fontSize:props.contentSize+2, fontWeight:'medium',  }}>
                  {props.description.trim() == "" ? "まだIDEAの説明がありません。" : props.description}
                </Box>
              </Typography>
            </Stack>

          </Stack>

            {/* 右側のコンポーネント IDEA募集詳細 + ボタン */}
          <Stack width={"45%"} justifyContent={'flex-start'}>
            <Box sx={{width: '100%',  boxShadow: '2px 6px 8px rgba(0, 0, 0, 0.3)'}}>
              <Stack spacing={props.spacing} direction={"column"} justifyContent={"space-between"} sx={{margin: "20px"}}>

                {/* アイデア募集詳細 タイトル・カテゴリ・募集期限 */}
                <Stack direction={"column"} spacing={2}>
                  {/* タイトル */}
                  <Typography component="div">
                    <Box sx={{color: "#9D9D9D", fontSize:props.titleSize+2, fontWeight: 'medium',  }}>募集IDEA</Box>
                    <Box sx={{ color: "#363636", fontSize:props.contentSize + 6, fontWeight: 'bold',  backgroundColor: props.title_hovered ? "#eeeeee" : "transparent", }}>{props.title}</Box>
                  </Typography>

                  <Stack direction={"row"} spacing={0.5} sx={{ backgroundColor: props.categories_hovered ? "#eeeeee" : "transparent", }}>
                    {[props.categories[0] ?? null, props.categories[1] ?? null, props.categories[2] ?? null].map((tag:string | null, index:number) => {
                      if(tag === null || tag === undefined){
                        return null;
                      }
                      return(
                        <Typography component="div" key={index}>
                          <Box sx={{color: "#383E86", cursor:"pointer", ":hover":{textDecoration:"underline"}, fontSize:props.contentSize-2, fontWeight: 'medium',  }}
                                    onClick={() => { 
                                      const queryString = new URLSearchParams({ categories: JSON.stringify([tag]) }).toString();
                                      window.location.href = "/search?mode=apply&" + queryString;}}># {tag}</Box>
                        </Typography>
                      )
                    })}
                  </Stack>


                  {/* IDEA募集期限 */}
                  <Stack direction={"column"} spacing={0.5} sx={{}}>
                    {/* 募集期限 */}
                    <Typography component="div">
                      <Box sx={{color: "#9D9D9D", fontSize:props.titleSize+2, fontWeight: 'medium',  }}>募集期限</Box>
                      <Box sx={{ color: "#363636", fontSize:props.contentSize + 6, fontWeight: 'bold', backgroundColor: props.deadline_hovered ? "#eeeeee" : "transparent", }}>{props.deadline !== "" ? format(toZonedTime(props.deadline, "UTC"), "yyyy年M月d日") : ""}</Box>
                    </Typography>

                    <BorderLinearProgress variant="determinate" value={ 0 } />
                    <Stack mt={0} direction={"row"} width={"100%"} justifyContent={"space-between"}>
                      <Box sx={{color: "#363636", fontSize:props.contentSize, fontWeight: 'medium',  }}>募集開始</Box>
                      <Box sx={{color: "#363636", fontSize:props.contentSize, fontWeight: 'medium',  }}>結果発表</Box>
                    </Stack>
                  </Stack>

                  {/* 募集期限 */}
                  <Typography component="div">
                    <Box sx={{color: "#9D9D9D", fontSize:props.titleSize+2, fontWeight: 'medium',  }}>IDEA採用特典詳細</Box>
                    <Box sx={{ color: "#363636", fontSize:props.contentSize +2, fontWeight: 'bold', backgroundColor: props.benefit_description_hovered ? "#eeeeee" : "transparent", }}>{props.benefit_description}</Box>
                  </Typography>

                </Stack>
              </Stack>
            </Box>

            {/* ボタン等 */}
            <Stack mt={3} spacing={2}>
              {/* アイデア投稿 */}
              <PostIdeaButton paddingY={8}  disabled={false} onClick={() => {}} fontSize={props.contentSize+3}/>
              {/* いいね */}
              <LikeButton
                onClick={() => {}}
                liked={false}
                padding={"8px"}
                fontSize={props.contentSize+3}
                num_of_likes={ 0 }/>
            </Stack>

          </Stack>

        </Stack>     
      </Stack>

  </Stack>
  );
};

export default ApplyView;
