import { Box, Typography, Card, Stack, Avatar} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { isMonday } from "date-fns";


export interface IdeaCardProps {
    profile_image_name?: string | null | undefined;
    profile_image_uri?: string | null | undefined;
    user_name: string;
    onClick: () => void;
    ratio?: number;
    radius: number;

    isMobile?: boolean;
}

const UserIcon: React.FC<IdeaCardProps> = (props: IdeaCardProps) => {

    let ratio = props.isMobile ? 0.7: 1;
    if(props.ratio !== (null || undefined)){
        ratio = props.ratio;
    }
    
  return (
    props.profile_image_name != null && props.profile_image_name != undefined  ? 
        <img src={process.env.REACT_APP_PUBLIC_URL+"/images/"+props.profile_image_name} style={{ borderRadius: props.radius * ratio, width: props.radius * 2 *ratio, height: props.radius * 2 * ratio, cursor:"pointer",objectFit: "cover",objectPosition: "center"}}/>
    :
    props.profile_image_uri != null && props.profile_image_uri != undefined  ? 
        <img src={props.profile_image_uri} style={{ borderRadius: props.radius * ratio, width: props.radius * 2 *ratio, height: props.radius * 2 * ratio, cursor:"pointer",objectFit: "cover",objectPosition: "center"}}/>
    :   <Avatar sx={{width: props.radius * 2 * ratio, height: props.radius * 2 * ratio, fontSize: props.radius *ratio}}>{props.user_name ? props.user_name[0] : null}</Avatar>
  );
};

export default UserIcon;
