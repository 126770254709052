
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useLocation } from 'react-router-dom';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import GroupIcon from '@mui/icons-material/Group';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import GavelIcon from '@mui/icons-material/Gavel';
import SettingsIcon from '@mui/icons-material/Settings';

const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

interface Props {
  width: string
}


const SideBar: React.FC<Props> = ({ width }) => {

  const [mode, setMode] = React.useState<string>("none") ;
  const location = useLocation();
  const [isLoaded, setIsLoaded] = React.useState(true);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  React.useEffect(() => {
    // 現在のパス名を取得し、modeに代入
    const currentPath = location.pathname;
    const pathSegments = currentPath.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    if (lastSegment) {
      setMode(capitalizeFirstLetter(lastSegment));
    } else {
      setMode('Ideas'); // パスが空の場合はmodeをリセット
    }
  }, [location]);

  return (
    <Stack zIndex={3} spacing={0.5} p={2} width={width} alignItems={"flex-start"} justifyContent={"flex-start"} sx={{position:"fixed", left:0, top:0, bottom:0, backgroundColor:"white",  boxShadow: '2px 6px 8px rgba(0, 0, 0, 0.1)'}}>
      <img alt="" onLoad={handleImageLoad} src={process.env.REACT_APP_PUBLIC_URL+"/RE-IDEA.png"} style={{width:"100%", padding: "0px 10px", margin:"20px 0px"}}/>
      
    {true &&
      <>
        <Typography sx={{ fontWeight:"bold", color:"lightgrey", fontSize:14, padding:"10px 10px 20px 10px"}}>
          MAIN MENU
        </Typography>
        {["Ideas", "Applies", "Users", "Crowdfundings"].map((title:string, index:number) => {
          return (
            <Stack onClick={()=>{window.location.href = "./"+title.toLowerCase();}} padding={"7px 20px"} alignItems={"center"} direction={"row"} width={"100%"} spacing={2} sx={{":hover":{backgroundColor:mode===title ? "#50569e" : "gainsboro", borderRadius:"10px"}, transition:"background-color 0.5s ease", cursor:"pointer",  backgroundColor:mode===title ? "#383E86" : "white", borderRadius:"10px"}}>
              {title === "Ideas" && <LightbulbIcon sx={{color:mode===title ? "white" : "#616161", fontSize:30,}}/>}
              {title === "Applies" && <img src={process.env.REACT_APP_PUBLIC_URL+"/board.png"} 
                style={{width:"30px", color:mode===title ? "white" : "#616161",
                        filter: mode===title ? "invert(88%) sepia(61%) saturate(0%) hue-rotate(229deg) brightness(107%) contrast(101%)"
                                            : "invert(20%) grayscale(1) brightness(200%)"}}/>}
              {title === "Users" && <GroupIcon sx={{color:mode===title ? "white" : "#616161", fontSize:30,}}/>}
              {title === "Crowdfundings" && <ShoppingCartIcon sx={{color:mode===title ? "white" : "#616161", fontSize:30,}}/>}
              <Typography sx={{ fontWeight:"bold", color:mode===title ? "white" : "#616161", fontSize:17,}}>
                {title}
              </Typography>
            </Stack>

          )
        })}

        <Typography sx={{ fontWeight:"bold", color:"lightgrey", fontSize:14, padding:"50px 10px 20px 10px"}}>
          PREFERENCES
        </Typography>
        {["Settings"].map((title:string, index:number) => {
          return (
            <Stack onClick={()=>{setMode(title)}} padding={"7px 20px"} alignItems={"center"} direction={"row"} width={"100%"} spacing={2} sx={{":hover":{backgroundColor:mode===title ? "#50569e" : "gainsboro", borderRadius:"10px"}, transition:"background-color 0.5s ease", cursor:"pointer",  backgroundColor:mode===title ? "#383E86" : "white", borderRadius:"10px"}}>
              {title === "Settings" && <SettingsIcon sx={{color:mode===title ? "white" : "#616161", fontSize:30,}}/>}
              <Typography sx={{ fontWeight:"bold", color:mode===title ? "white" : "#616161", fontSize:18,}}>
                {title}
              </Typography>
            </Stack>

          )
        })}
      </>
    }
      
    </Stack>
  );
};

export default SideBar;