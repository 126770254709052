// PopupComponent
import React, { useState, useEffect, useContext, useRef } from 'react';
import Box from '@mui/material/Box';
import { InputAdornment, IconButton, Typography, Button, Modal, Stack, TextField, Card, Avatar, Divider } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { medium } from '../../interfaces/recruitment';
import ApplyForm from '../form/applyForm';
import ApplyView from '../form/applyView';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "80%",
    backgroundColor: '#F6F8FA',
    borderRadius:5,
};


// Propsの型を定義
interface Props {
    handleClose: () => void;
    open: boolean;
    recruitID?: string | null;
    isMobile?: boolean;
}

interface RecruitResponse {
    data: medium | null;
    isValid: boolean;
    setIsValidData: React.Dispatch<React.SetStateAction<boolean>>;
  }


const PopupApplyEditAndPreview :React.FC<Props> = ({ handleClose, open, isMobile }) => {

    const [title, setTitle] = React.useState<string>("");
    const [title_hovered, setTitleHoverd] = React.useState<boolean>(false);

    const [deadline, setDeadline] = React.useState<string>("");
    const [deadline_hovered, setDeadlineHovered] = React.useState<boolean>(false);

    const [selectedUserID, setSelectedUserID] = React.useState<string>("");
    const [selectedUserID_hovered, setSelectedUserIDHovered] = React.useState<boolean>(false);

    const [benefit_num, setBenefitNum] = React.useState<string>("");
    const [benefit_num_hovered, setBenefitNumHovered] = React.useState<boolean>(false);

    const [benefit_description, setBenefitDescription] = React.useState<string>("");
    const [benefit_description_hovered, setBenefitDescriptionHovered] = React.useState<boolean>(false);

    const [description, setDescription] = React.useState<string>("");
    const [description_hovered, setDescriptionHoverd] = React.useState<boolean>(false);

    const [midjourney_text, setMidjourneyText] = React.useState<string>("");

    const [categories, setCategories] = useState<(string | null)[]>([]);
    const [categories_hovered, setCategoriesHoverd] = React.useState<boolean>(false);

    const [display_base64, setDisplayBase64] = React.useState<string>("");
    const [display_base64_hovered, setDisplayBase64Hoverd] = React.useState<boolean>(false);

    const resetInformation = () => {
        setTitle("");
        setDescription("")
        setSelectedUserID("")
        setDeadline("")
        setCategories([])
        setBenefitDescription("")
        setDisplayBase64("")
        setMidjourneyText("")
        sessionStorage.removeItem('editData');
    }

    useEffect(() => {
        // コンポーネントの初回レンダリング時にsessionStorageから値を読み込む
        const storedValue = sessionStorage.getItem('editData');
        if (storedValue) {
            setTitle(JSON.parse(storedValue).title);
            setDeadline(JSON.parse(storedValue).deadline)
            setSelectedUserID(JSON.parse(storedValue).selectedUserID)
            setBenefitNum(JSON.parse(storedValue).benefit_num)
            setBenefitDescription(JSON.parse(storedValue).benefit_description)
            setMidjourneyText(JSON.parse(storedValue).midjourney_text);
            setDescription(JSON.parse(storedValue).description);
            setCategories(JSON.parse(storedValue).categories);
            setDisplayBase64(JSON.parse(storedValue).display_base64);
        }
    }, []);

    useEffect(() => {
        const editData = { 
            title: title, 
            description: description, 
            selectedUserID: selectedUserID,
            deadline: deadline,
            benefit_num: benefit_num,
            benefit_description: benefit_description,
            categories:categories, 
            midjourney_text: midjourney_text,
            display_base64: display_base64 }; // 保存したいJSONオブジェクト
        sessionStorage.setItem('editData', JSON.stringify(editData)); 
    }, [title, description,selectedUserID, benefit_description, benefit_num, categories, display_base64, midjourney_text]);
    
    return (
        <Modal open={open} onClose={handleClose} sx={{border:"none"}}>
            <Fade in={open}>
                <Stack>
                    {/* ばつボタン */}
                    <div style={{ position: 'absolute', zIndex:3, top:"calc( 50% - 35% )", right:"8%", transform: 'translate(-75%, -75%)',}}>
                        <IconButton onClick={()=>{handleClose(); }}>
                            <CloseIcon  sx={{ fontSize:isMobile? 22: 32}} />
                        </IconButton>
                    </div>

                    {/* 全体 */}
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-evenly"} sx={style}>
                        {/* プレビュー画面 */}
                        <Stack direction={"row"} width={"90%"} height={"85%"} sx={{backgroundColor:"white"}}>
                            <Stack width={"90%"} height={"100%"} alignItems={"center"} justifyContent={"center"} >
                                <Stack width={"95%"} height={"90%"} alignItems={"center"} justifyContent={"center"} sx={{ border:"1px solid lightgrey", backgroundColor:"snow"}}>
                                    <ApplyView
                                        title={title}
                                        title_hovered={title_hovered}
                                        selectedUserID={selectedUserID}
                                        selectedUserID_hovered={selectedUserID_hovered}
                                        benefit_description={benefit_description}
                                        benefit_description_hovered={benefit_description_hovered}
                                        deadline={deadline}
                                        deadline_hovered={deadline_hovered}
                                        description={description}
                                        description_hovered={description_hovered}
                                        categories={categories}
                                        categories_hovered={categories_hovered}
                                        display_base64={display_base64}
                                        display_base64_hovered={display_base64_hovered}
                                        contentSize={10}
                                        titleSize={10}
                                        spacing={1}
                                    />
                                </Stack>
                            </Stack>
                            <Divider sx={{height:"100%", backgroundColor:"snow"}} orientation="vertical"/>

                        {/* アイデア募集投稿画面 */}
                        <Stack width={"60%"} height={"100%"} sx={{backgroundColor:"white"}}>
                            <ApplyForm 
                                resetInformation={resetInformation}
                                recruitID={""} 
                                isMobile={isMobile}
                                title={title}
                                setTitle={setTitle}
                                setTitleHoverd={setTitleHoverd}
                                selectedUserID={selectedUserID}
                                setSelectedUserID={setSelectedUserID}
                                setSelectedUserIDHovered={setSelectedUserIDHovered}
                                benefit_num={benefit_num}
                                setBenefitNum={setBenefitNum}
                                setBenefitNumHovered={setBenefitNumHovered}
                                benefit_description={benefit_description}
                                setBenefitDescription={setBenefitDescription}
                                setBenefitDescriptionHovered={setBenefitDescriptionHovered}
                                deadline={deadline}
                                setDeadline={setDeadline}
                                setDeadlineHovered={setDeadlineHovered}
                                categories={categories}
                                setCategories={setCategories}
                                setCategoriesHoverd={setCategoriesHoverd}
                                midjourney_text={midjourney_text}
                                setMidjourneyText={setMidjourneyText}
                                description={description}
                                setDescription={setDescription}
                                setDescriptionHoverd={setDescriptionHoverd}
                                display_base64={display_base64}
                                setDisplayBase64={setDisplayBase64}
                                setDisplayBase64Hoverd={setDisplayBase64Hoverd}
                            />
                        </Stack>
                        </Stack>
                        

                        
                    </Stack>
                </Stack>
            </Fade>
        </Modal>
    );
};

export default PopupApplyEditAndPreview;