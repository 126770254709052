import React from 'react';
import Box from '@mui/material/Box';
import { Typography, Button } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';

interface Props {
    onClick?: () => void;
    fontSize?: number;
    paddingX?: number;
    paddingY?: number;
    disabled?: boolean;
}


// 投稿ボタン
const PostIdeaButton: React.FC<Props> = (props) => {
    let fontSize = 24;
    let paddingX = 20;
    if(props.fontSize != (null || undefined) ){
        fontSize = props.fontSize;
    }
    if(props.paddingX != (null || undefined) ){
        paddingX = props.paddingX;
    }
    return (
        <Button variant='contained' disabled={props.disabled == true ? true : false } sx={{width:"100%", minWidth:"10px", padding: (props.paddingY ? props.paddingY : 20) + "px " + paddingX + "px", borderRadius:"0px", backgroundColor: "#FE595B", ":hover":{backgroundColor: "#CD3B3D"}}}
            onClick={() => {
                if(props.onClick !== (undefined)){
                    props.onClick()
                }
            }}>
            <Typography component="div">
            <Box sx={{color: "#FFFFFF", fontSize: fontSize, fontWeight: 'medium',  }}>投稿する</Box>
            </Typography>
            <CreateIcon sx={{fontSize: fontSize, marginLeft:"16px", color:"white"}}/>
        </Button>
    );
};

export default PostIdeaButton;
