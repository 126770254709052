import React, { useState, useEffect, useContext, useRef } from 'react';
import Box from '@mui/material/Box';
import { InputAdornment, IconButton, Typography, Button, Modal, Stack, TextField, Card, Avatar } from '@mui/material';
import Fade from '@mui/material/Fade';

const style = {
    position: 'absolute',

    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 400,
    overflowY: "auto",
    backgroundColor: 'white',
    justifyContent: "center",
    alignItems: "center",
    borderRadius:5
};
const styleSP = {
    position: 'absolute',

    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: 400,
    // overflowY: "scroll",
    backgroundColor: 'white',
    justifyContent: "center",
    alignItems: "center",
    borderRadius:5,
};

// Propsの型を定義
interface Props {
    title: string
    handleClose: () => void;
    open: boolean;
    isMobile?: boolean;
    deleteFunc: () => void
    type?: string;
}

const PopupConfirmation: React.FC<Props> = ({ title, handleClose, open, isMobile, deleteFunc, type }) => {

  const PASSWORD = "totoro6213"
  const [password, setPassword] = useState<string>("")
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    if(open === true){
        setPassword("")
        setError(false)
    }
  }, [open]);
   

  return (
    <Modal open={open} onClose={handleClose} sx={{border:"none"}}>
        <Fade in={open}>
                <Stack sx={isMobile ? styleSP : style}>
                    <Stack width={isMobile ? "95%" : "90%"} spacing={2} p={5}alignItems={"center"} sx={{backgroundColor: "white", borderRadius:"20px" }}>
                        
                        <Typography sx={{fontWeight:"bold", color:"red", fontSize: 24}}>
                            【IDEA{type==="apply" ? "募集" : ""}を完全に削除しようとしています】
                        </Typography>
                        <Typography sx={{fontWeight:"bold", color:"red", fontSize: 15}}>
                            該当IDEA{type==="apply" ? "募集" : ""}:『{title}』
                        </Typography>

                        <Typography sx={{ whiteSpace:"pre-wrap", lineHeight:1.7, fontWeight:"bold", color:"#363636", fontSize: isMobile ? 18: 14}}>
                            {"本当に削除しますか？セキュリティ保護のため、パスワードを入力してください。"}
                        </Typography>

                        <TextField fullWidth error={error} helperText={error ? "パスワードが間違っています。" : undefined} placeholder='パスワードを入力' type='password' value={password} onChange={(e) => {setError(false);setPassword(e.target.value)}}/>

                        <Stack spacing={2} direction={"row"}>
                            <Button onClick={() => {handleClose()}}>やめる</Button>
                            <Button onClick={() => {
                                if(password === PASSWORD){
                                    deleteFunc()
                                    setError(false)
                                    handleClose()
                                }else{
                                    setError(true)
                                }
                            }}>削除</Button>
                        </Stack>
                    </Stack>
                </Stack>
        </Fade>
    </Modal>
  );
};

export default PopupConfirmation;