import React, { useState, useEffect, useContext } from 'react';
import { Grid,Stack, FormGroup, Checkbox, Card, Button, TextField, Typography,InputAdornment, Box } from '@mui/material';
import { useIsMobile } from "./../components/isMobile"

function NotFound() {
    const isMobile = useIsMobile()
  
  return (
    <Stack  minHeight={"700px"} justifyContent={"center"} alignItems={"center"} sx={{backgroundColor:"#F6F8FA"}}>
        <Stack width={isMobile ? "80%" : "30%"} spacing={6} p={5} minHeight={"400px"} alignItems={"center"} sx={{backgroundColor: "white", borderRadius:"20px" }}>
            <Typography sx={{fontWeight:"bold", color:"#363636", fontSize: 18}}>
                このページは存在しません
            </Typography>

            <Typography sx={{fontWeight:"bold", color:"#363636", fontSize: 32}}>
                404
            </Typography>

            <Typography sx={{fontWeight:"bold", color:"#363636", fontSize: 12}}>
                スマホ版はまだ対応してないのでPCで開いてください。
            </Typography>
        </Stack>
    </Stack>
  )

}

export default NotFound;
