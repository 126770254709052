import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { InputAdornment, Grid, IconButton, Typography, Button, Stack, TextField, Card, Avatar } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { TextareaAutosize } from '@mui/material';
import { makeStyles } from '@mui/material';

interface TagsProps {
    rows: number
    value: string,
    placeholder: string,
    setValue: Dispatch<SetStateAction<string>>
    max: number,
    error?: boolean
    ratio?: number;
}

const LimitedMultilineOutlined: React.FC<TagsProps> = ({ rows, value, setValue, max, placeholder, error, ratio }) => {
    
    return(
        <Stack direction={"column"} sx={{width:"100%"}} >
            <TextField variant="outlined" rows={rows} error={error ? error: false} multiline sx={{ width:"100%",marginTop:"0px", marginBottom:"0px",
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        border: error ? "1.5px solid red": "none",
                        borderRadius: '10px',
                        padding: "0px",
                    },
                },
                "& .MuiInputBase-multiline ":{
                    padding: "0px 0px", // マルチラインの場合、余白をリセットする
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {border: error ? "1.5px solid red": "none", },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': { border: error ? "1.5px solid red": "none",},}}
                inputProps={{style: {fontSize: ratio ? ratio * 16 : 16, borderRadius:"10px", padding: ratio ? ratio * 14 : 14, lineHeight: ratio ? ratio * 20 + "px" : '20px', backgroundColor:"#F6F6F6"}}}
                value={value}
                placeholder={placeholder}
                onChange={(e) => {
                    if(e.target.value.length > max){
                        setValue(e.target.value.substring(0, max))
                    }else{
                        setValue(e.target.value)
                    }
                }}
            />
            <Stack direction={"row"} justifyContent={"flex-end"}>
                <Typography sx={{fontSize: ratio ? ratio * 14 : 14, color: value.length >= max ? "#e60000" : "grey"}}>
                    {value.length} / {max}
                </Typography>
            </Stack>
        </Stack>
    )
    
}

export default LimitedMultilineOutlined;
