import React from 'react';
import Box from '@mui/material/Box';
import { Typography, Button, Stack } from '@mui/material';

interface Props {
    onClick: () => void;
    bottom?: number | null;
    right?: number | null;
    ratio?: number;
}


// 下書き ボタン
const FixedPlus: React.FC<Props> = (props) => {
    return (
        <Stack pb={0.5} justifyContent={"center"} alignItems={"center"} sx={{ cursor:"pointer", zIndex:10, position:'fixed', bottom:props.bottom ?? 50, right:props.right ?? 50,  height:props.ratio ? 75 * props.ratio : "75px", width:props.ratio ? 75 * props.ratio : "75px", backgroundColor: "#383E86", borderRadius:props.ratio ? 75 * props.ratio : "75px", ":hover":{opacity: 0.9}}}
                onClick={() => {props.onClick()}}>
            <Box textAlign={"center"} alignItems={"center"} component="div" width={"100%"} sx={{color: "white", fontSize: props.ratio ? 36 * props.ratio : 36, fontWeight: 'bold',letterSpacing: '0px'}}>
                +
            </Box>
        </Stack>
    );
};

export default FixedPlus;
