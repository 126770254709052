import React from 'react';
import Box from '@mui/material/Box';
import { Typography, Button, CircularProgress } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';

interface Props {
    onClick: () => void;
    text?: string;
    width?: string;
    height?: string;
    fontSize?: string;
    borderRadius?: string;
    boxShadow?:string;
    backgroundColor?:string;
    isMobile?: boolean;
    disabled?: boolean;
    loading?: boolean;
}


// 投稿ボタン
const MoreButton: React.FC<Props> = ({onClick, text, width, height, fontSize, borderRadius, boxShadow, backgroundColor, loading, isMobile, disabled}) => {
    
    return (
        <>
            {/* 「もっと見る」ボタン */}
            <Button variant='contained' disabled={disabled == true} size='large' sx={{backgroundColor: disabled ? "none" : backgroundColor ? backgroundColor :"#383E86",
                width: isMobile ? (width ? width :'270px') : (width ? width :'375px'),
                height: height == undefined ? "8em" : height,
                borderRadius: borderRadius ? borderRadius : '0px',
                boxShadow: boxShadow ? boxShadow : '3px 4px 4px rgba(0, 0, 0, 0.25)',
                '&:active': {
                    backgroundColor: backgroundColor ? backgroundColor : "#383E86", // 押したときの色を変更
                    opacity: 0.9,
                },
                '&:hover': {
                    backgroundColor: backgroundColor ? backgroundColor : "#383E86",
                    opacity: 0.9,
                }
                }}
                onClick={() => {onClick()}}>
                {loading &&
                    <CircularProgress size={20} sx={{marginRight: "10px"}} color="inherit" />
                }
                <Typography sx={{ fontSize: fontSize ? fontSize :isMobile ? "18px" : '24px',fontWeight: 'bold',}}>
                    {text ? text : "もっと見る"}
                </Typography>
            </Button>
        </>
    );
};

export default MoreButton;
