import { Routes, Route, useNavigate, useLocation } from "react-router-dom"; // 追加
import React, { useState, useEffect, createContext, useContext, } from 'react';
import {io, Socket} from 'socket.io-client';
import { CssBaseline, Stack } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Ideas from "../pages/Ideas";
import SideBar from "../components/sidebar/sideBar";
import  NotFound  from "./../pages/NotFound"
import {useIsMobile} from "./../components/isMobile"
import Applies from "./../pages/Applies";
import { useQuery, useMutation, UseQueryResult, useQueryClient } from 'react-query';
import { simple as simple_category } from "@/interfaces/category";
import { simple as simple_recruit } from "@/interfaces/recruitment";
import Login from "./../pages/Login";
import axios from "axios";

const theme = createTheme({
  typography: {
    fontFamily: [
      'Hiragino Sans', // ヒラギノフォントを指定
      'Arial', // 代替フォント
      'sans-serif', // サンセリフフォント
    ].join(','),
  },
});

// Socketオブジェクトとユーザーオブジェクトの型を定義
interface Context {
  socket: Socket;
  user: any;
  categories: simple_category[];
  recruits: simple_recruit[];
}

// Contextの初期値を設定する
export const MyContext = createContext<Context>({socket: {} as Socket, user: {}, categories: [], recruits: [] });


// ルーターによりURLで分岐
function Router () {
  const queryClient = useQueryClient();
  const [socket, setSocket] = useState<Socket | null>(null);
  // カテゴリ一覧
  const { data:categories }: UseQueryResult<simple_category[] | null, Error> = useQuery("categories", () => fetch(process.env.REACT_APP_API_URL+'/categories/find').then(res =>res.json()));
  // アイデア募集一覧
  const { data:recruits }: UseQueryResult<simple_recruit[] | null, Error> = useQuery("recruits", () => fetch(process.env.REACT_APP_API_URL+'/recruitments/find/simple').then(res =>res.json()));
  // ユーザー情報
  const { isLoading: userIsLoading, data:user }: UseQueryResult<any | null, Error> = useQuery("user", () => fetch(process.env.REACT_APP_API_URL+'/users/findbysess',{
    credentials: 'include', // クッキーを含めるオプション
  }).then(res =>res.json()));

  useEffect(() => {
    setSocket(io(process.env.REACT_APP_SOCKET_URL+''));
  }, []);

  console.log(recruits)

  // ソケットに自分の識別情報を送る
  useEffect(() => {
    if (socket !== null) {
      socket.emit("join", {user_id: user?.user_id, socket_id:socket.id})
    }
  }, [socket]);

  const SIDE_BAR_WIDTH : string = "300px"
  const isMobile = useIsMobile()

  if(socket === null){
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MyContext.Provider value={{socket:socket, user:user, categories:categories ?? [], recruits: recruits ?? []}}>
        {/* ログインしていない状態なのであれば */}
        {userIsLoading === false &&
          (
            user?.user_id !== "dab2dbd5-2971-11ef-87af-0242ac130007" ? <Login success_func={()=>{queryClient.invalidateQueries('user');}}/>
            :
            <>
              {isMobile === false && <SideBar width={SIDE_BAR_WIDTH}/>}
              {isMobile === true ? <NotFound/>
              :
              // 冗長であるが、ログイン状態でなければ大事な部分を見せない
              user?.user_id !== undefined &&
                <Stack sx={{position:"absolute", left:SIDE_BAR_WIDTH, top:0, right:0, bottom:0, overflow: "auto"}}>
                  <Routes>
                      <Route path="/" element={ <Ideas/>} />
                      <Route path="/ideas" element={ <Ideas/>} />
                      <Route path="/applies" element={ <Applies/>} />
                  </Routes>
                </Stack>
              }
            </>
          )

        }
      </MyContext.Provider>
        
    </ThemeProvider>
  );
}

export default Router;
