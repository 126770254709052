import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { FormControl,SelectChangeEvent, InputLabel, Select, MenuItem,MenuProps, Grid, styled, Paper, IconButton, Typography, Button, Stack, TextField, Card, Avatar } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { TextareaAutosize } from '@mui/material';
import { makeStyles } from '@mui/material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';



interface TagsProps {
    title?: string;
    sort_value: string;
    labels: string[];
    values: string[];
    default_lables?: string[]
    setSortValue: Dispatch<SetStateAction<string>>;
    bold?:boolean;
    error?:boolean;

    isMobile?: boolean;
    ratio? :number;
}

const FlexSortSelector2: React.FC<TagsProps> = ({ title, sort_value, labels, values, default_lables, setSortValue, error, ratio, isMobile, bold }) => {
    // カスタムスタイルのPaperコンポーネント
    const menuProps = {
        PaperProps: {
            sx: {
                borderRadius: '15px',
                minWidth: `${140 * (ratio ? ratio : 1)}px`,
                marginTop: '10px',
            },
        },
    };
    const [index, setIndex] = useState<number>(values.indexOf(sort_value) ?? 0)
    return(
        <Stack spacing={1}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 100 * (ratio ? ratio : 1)}}>
                {title && <InputLabel sx={{ fontSize:isMobile ? '14px' : '16px',  }}>{title}</InputLabel>}
                <Select value={index} onChange={(e) => {
                    console.log(index)
                    setSortValue(values[Number(e.target.value)])
                    setIndex(Number(e.target.value))
                }} sx={{fontSize: 14 * (ratio ? ratio : 1) +"px",border: error ? "1.5px solid red" : "none", borderRadius:"10px", fontWeight: bold===false ? "medium" : "bold", textDecoration: 'none' }}
                    MenuProps={menuProps}
                >
                    {[...(default_lables ?? []), ...labels].map((label:string, _index:number) => {
                        return (
                            <MenuItem value={_index} sx={{fontSize:14 * (ratio ? ratio : 1) +"px", padding: 5 * (ratio ? ratio : 1) + "px" + 25 * (ratio ? ratio*2 : 2) + "px", minHeight: 32 * (ratio ? ratio : 1) + "px"}}>{labels[_index]}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Stack>
    )
    
}

export default FlexSortSelector2;
